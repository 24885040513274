@import "mixins/_transitions";
@import "variables/_colors";

.container {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  text-align: center;
  :global(.material-icons) {
    font-size: 200px;
    opacity: 0.25;
    color: #b71c1c;
  }
  a {
    font-weight: bold;
    color: $primary1Color;
    @include link-hover;
  }
  h3 {
    max-width: 400px;
    margin-top: 0;
  }
}
