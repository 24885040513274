/**
* A stylesheet to manage all the form elements
*/
@inputHeight: 44px;

// Input labels
label {
  font-weight: 700;
  font-size: 15px;
  color: @color-grey-mid-dark;
  line-height: 20px;
  display: block;
  margin-bottom: 3px;
}

// Input fields
input[type="text"].styled,
input[type="password"].styled,
input[type="email"].styled,
input[type="url"].styled,
input[type="number"].styled,
textarea.styled,
select.styled {
  .border-radius(3px);
  .box-shadow(inset 1px 1px 4px 0 @color-grey-border);
  width: 100%;
  background-color: white;
  border: 1px solid @color-grey-medium-light;
  font-weight: 600;
  font-size: 15px;
  color: @color-grey-type;
  padding: 0 10px;
  height: @inputHeight;
  line-height: @inputHeight;
  letter-spacing: -0.5px;
  &:focus {
    border-color: @color-emerald;
    color: @color-grey-type-dark;
  }
}

// styles for forms using styled inputs (card view)
.form-page,
.card-edit-modal {
  input[type="text"].styled,
  input[type="password"].styled,
  input[type="email"].styled,
  input[type="url"].styled,
  input[type="number"].styled,
  textarea.styled,
  select.styled {
    .border-radius(2px);
    background-color: white;
    border: 1px solid @color-form-border;
    box-shadow: none;
    color: @color-grey-type;
    font-size: 16px;
    font-weight: 600;
    height: 100%;
    line-height: 100%;
    letter-spacing: 0;
    padding: 8px;
    width: 100%;
    &:focus {
      border-color: @color-form-border-active;
      color: @color-grey-type;
    }
  }
}
// Adjust heights for textarea
textarea {
  height: auto;
  line-height: 18px;
  padding: 10px;
}

// A wrapper that sits around all input fields
.input-wrapper {
  position: relative;
  margin-bottom: 20px;

  &.with-prefix {
    .flex-block();

    // Text input
    input[type="text"] {
      .border-radius(0 3px 3px 0);
      .flex-grow();
      width: auto;
    }
  }

  // Input fields that have adjacent buttons need a flex block layout
  &.with-button {
    .flex-block();

    &.with-file-upload {
      input[type="text"] {
        .border-radius(0px);
      }
    }

    // Text input
    input[type="text"] {
      .border-radius(3px 0 0 3px);
      .flex-grow();
      width: auto;
      line-height: @inputHeight;
      height: @inputHeight;
    }

    // Twitter typeahead field styles
    .twitter-typeahead {
      .flex-grow();
      input[type="text"] {
        width: 100%;
      }
    }

    // Twitter typeahead dropdown styles
    .tt-dropdown-menu {
      border: 1px solid @color-grey-medium-light;
      left: -1px !important;
      right: -1px !important;
      max-height: 136px;
      overflow: auto;
      background-color: white;
    }
  }

  &.with-hint {
    margin-bottom: 0;
  }
}

// A prefix element which sits adjacent to the input field (spacing provided by flex block)
.input-prefix {
  .border-radius(3px 0 0 3px);
  .box-shadow(inset -1px -1px 4px 0 @color-grey-border);
  background-color: @color-grey-border;
  font-size: 15px;
  color: @color-grey-mid;
  padding: 0 10px;
  height: @inputHeight;
  line-height: @inputHeight;
  border: 1px solid @color-grey-medium-light;
  border-right: 0;
  width: 75px;
  font-weight: bold;
  text-align: right;
}

// A button which sits adjacent to the input field (spacing provided by flex block)
.input-button {
  .box-shadow(inset -1px -1px 4px 0 @color-grey-border);
  .border-radius(0 3px 3px 0);
  .default-transition();
  text-transform: uppercase;
  border-style: solid;
  border-color: @color-grey-medium-light;
  border-width: 1px 1px 1px 0;
  color: @color-grey-mid;
  font-size: 12px;
  background-color: @color-grey-super-light;
  display: inline-block;
  height: @inputHeight;
  padding: 0 20px;
  line-height: @inputHeight;
  font-weight: 600;
  .icon {
    margin-right: 10px;
    display: inline-block;
  }
  &.active {
    .box-shadow(none);
    cursor: pointer;
    background-color: @color-emerald;
    color: white;
    &:hover {
      background-color: darken(@color-emerald, @darkenPercentage);
    }
  }
}

// A hint to describe the purpose of the input field
.input-hint {
  color: @color-grey-mid-light;
  font-size: 12px;
  margin-top: 5px;
  font-weight: 600;

  &.hint-padding {
    margin-bottom: 20px;
  }
}

// A wrapper to align the action buttons
.actions-wrapper {
  text-align: right;
  .action-positive {
    margin-left: 10px;
  }
}

.actions-wrapper-left {
  .action-positive {
    margin-right: 10px;
  }
}

// Action buttons
.action-positive,
.action-cancel,
.action-back {
  .pill();
}

// Simple Select
.simple-select {
  padding: 0;
  margin: 0;
  width: auto;
  border-radius: 3px;
  overflow: hidden;
  background-color: white;
  background: white url("/img/arrowdown.gif") no-repeat 90% 50%;
  border: 2px solid @color-grey-border;
  select {
    padding: 7px 0;
    width: 100%;
    font-weight: 600;
    border: 0;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
  }
  select:focus {
    outline: 0;
  }
}

.material-ui {
  label {
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
    line-height: inherit;
    display: inherit;
    margin-bottom: inherit;
  }

  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="url"],
  textarea,
  select {
    .border-radius(inherit);
    .box-shadow(inherit);
    width: inherit;
    background-color: inherit;
    border: inherit;
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
    padding: inherit;
    height: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    &:focus {
      border-color: inherit;
      color: inherit;
    }
  }

  // Adjust heights for textarea
  textarea {
    height: inherit;
    line-height: inherit;
    padding: inherit;
  }
}
.table-filter {
  .global-select {
    box-shadow: none;
    border-color: #979797;
  }
}
