@header-font-size: (17 / 60) * @headerHeight;

.base-header {
  .default-transition();
  height: @headerHeight;
  background-color: white;
  border-bottom: 1px solid @color-grey-border;

  &.blend {
    background-color: inherit;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border: 0;
    .logo {
      border: 0;
    }
  }

  .siren {
    .border-radius(100px);
    display: none;
    background-color: @color-emerald;
    float: left;
    margin: 7px 9px 0 0;
    width: 8px;
    height: 8px;
  }

  .logo {
    color: @color-grey-type;
    font-size: 23px;
    line-height: @headerHeight;
    height: @headerHeight;
    display: block;
    padding: 0 20px;
    left: 0;
    text-align: center;
    float: none;
    border-bottom: 1px solid @color-grey-border;
    background-color: transparent;

    @media @tablet {
      background-color: @color-grey-ultra-light;
      float: left;
    }
  }
}

.base-header-logo {
  float: none;
  display: block;
  width: 30%;
  margin: 0 auto;

  @media @tablet {
    float: left;
    display: inline-block;
    width: auto;
  }
}

.base-header-mobile-nav {
  .default-transition();
  left: -240px;
  padding-bottom: 80px;
  overflow: hidden;
  width: 240px;
  min-height: 300px;
  height: 100%;
  top: 0;
  z-index: 100;
  background-color: @color-grey-border;
  position: fixed;
  padding: 18px 0 0 0;

  .logo {
    text-align: center;
    color: @color-grey-type;
  }

  .links {
    padding: 20px;
    a {
      display: block;
      color: @color-grey-type-dark;
      border-bottom: 1px solid @color-grey-light;
      padding: 10px 0;
    }
  }
}

.base-header-mobile-toggle {
  display: block;
  position: absolute;
  left: 20px;
  top: 0;

  a {
    color: @color-grey-type-dark;
    font-size: 16px;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 600;
    padding: 0;
    line-height: @headerHeight;

    i {
      margin-right: 4px;
    }
  }

  @media @tablet {
    display: none;
  }
}

.base-header-slogan {
  color: @color-grey-medium;
  font-size: 14px;
  float: left;
  margin-left: 20px;
  line-height: @headerHeight;
  display: none;

  @media @tablet {
    display: block;
  }
}

.base-header-links-loggedout {
  float: right;
  margin-top: 15px;
  display: none;

  @media @tablet {
    display: block;
  }

  li {
    display: inline-block;
    margin-right: 40px;

    &.blog {
      border-right: 1px solid @color-grey-mid-light;
      padding-right: 29px;
    }

    a {
      font-size: 16px;
      font-weight: 600;
      color: @color-grey-type;

      &:hover {
        color: @color-grey-type-dark;
      }
    }

    &:last-child {
      margin-right: 20px;
    }
  }
}

.base-header-discover {
  float: right;
  vertical-align: middle;
  margin-right: 25px;
  font-weight: 600;
  font-size: 16px;
  color: @color-grey-mid;
  margin-top: 20px;
  display: none;

  @media @tablet {
    display: block;
  }

  &.active,
  &:hover {
    color: @color-grey;
  }
}

.base-header-search {
  position: relative;
  float: left;
  margin-right: 5px;
  margin-left: 35px;
  height: @headerHeight;
  border-left: 1px solid @color-grey-border;
  display: none;

  @media @tablet {
    display: block;
  }

  i {
    display: block;
    color: @color-grey-type;
    font-size: 13px;
    z-index: 1;
    line-height: @headerHeight;
    float: left;
    padding: 0 10px 0 30px;
  }

  input {
    font-size: @header-font-size;
    border: 0;
    background-color: white;
    width: 190px;
    height: 40px;
    line-height: 40px;
    margin-top: (@headerHeight - 40) / 2;
    float: left;
  }

  .tt-dropdown-menu {
    .border-radius(0 0 4px 4px);
    .box-shadow(fade(@color-grey-type-dark, 20%) 0 3px 5px);
    max-height: 300px;
    overflow-y: auto;
    width: 300px;
    border-top: 1px solid @color-grey-border;
    top: 50px !important;
  }

  .tt-dataset-vendors,
  .tt-dataset-default {
    width: 100%;
  }
}

.base-header-links-primary {
  float: left;
  line-height: @headerHeight;
  margin-left: 40px;
  display: none;
  font-size: @header-font-size;

  @media @tablet {
    display: block;
  }

  li {
    display: inline-block;
    margin-right: 32px;

    &:last-child {
      margin-right: 0;
    }

    &.has-siren {
      .siren {
        display: block;
      }
    }

    a.active {
      color: @color-grey;
    }

    &.light a {
      font-weight: 400;
    }
  }

  a {
    .default-transition();
    font-weight: 600;
    color: @color-grey-type;

    &:hover {
      color: darken(@color-grey-type, @darkenPercentage);
    }
  }
}

.base-header-links-user {
  display: none;
  float: right;

  @media @tablet {
    display: block;
  }

  a,
  .profile-dropdown-wrapper {
    display: inline-block;
    vertical-align: middle;
  }

  .profile-dropdown-wrapper {
    position: relative;
    border-right: 1px solid @color-grey-border;
    padding-right: 20px;
    margin-right: 20px;
    height: @headerHeight;
  }

  .profile-dropdown {
    .border-radius(3px);
    .default-transition();
    .transform(translateY(10px));
    .opacity(0);
    pointer-events: none;

    position: absolute;
    top: 61px;
    left: -287px;
    background-color: @color-grey-super-light;
    width: 332px;
    z-index: 100;
    border: 1px solid @color-grey-border;
    font-size: 13px;
    font-weight: 600;
    background-clip: padding-box;

    &.active {
      .opacity(1);
      .transform(translateY(0));
      pointer-events: auto;
    }

    .profile {
      background-color: white;
      padding: 20px;
      .photo {
        .border-radius(4px);
        margin-top: 0;
        float: left;
        width: 96px;
        height: 96px;
      }
      .content {
        margin-left: 20px;
        float: left;
        font-weight: 600;
        color: @color-grey-type;
        width: 172px;
      }
      .email {
        .ellipsis();
        font-size: 13px;
        line-height: 14px;
        float: left;
        clear: left;
        max-width: 172px;
      }
      .business {
        font-size: 19px;
        line-height: 21px;
        float: left;
        clear: left;
        color: @color-grey-type;
      }
      a {
        float: left;
        clear: left;
      }
      .profile-button {
        .button(4px, 30px, 11px, capitalize);
        float: left;
        clear: left;
      }
    }

    .edit {
      font-size: 12px;
      line-height: 30px;
      color: @color-emerald;
      cursor: pointer;
      float: left;
      clear: left;
      &:hover {
        color: darken(@color-emerald, @darkenPercentage);
      }
    }

    .logged-in-as {
      position: relative;
      width: 100%;
      padding: 0 20px;
      line-height: 15px;
      color: @color-grey-mid-light;

      .photo {
        .border-radius(18px);
        margin-top: 10px;
        float: left;
        cursor: default;
      }
      .edit {
        float: right;
        color: @color-emerald;
        line-height: 56px;
        clear: none;
        &:hover {
          color: darken(@color-emerald, @darkenPercentage);
        }
      }
    }

    .switch {
      max-height: 130px;
      overflow: auto;
      a {
        position: relative;
        padding: 15px 20px;
        width: 100%;
        display: block;
        border-bottom: 1px solid @color-grey-border;

        &:last-child {
          border-bottom: 0;
        }

        &:hover {
          background-color: white;
        }
      }
      .photo {
        .border-radius(4px);
        height: 50px;
        width: 50px;
        margin-top: 0;
      }
      .icon-switch {
        .center-vertical();
        right: 5px;
        color: @color-grey-mid-light;
        font-size: 11px;
      }
      .content {
        .center-vertical();
        color: @color-grey-type;
        display: block;
        float: left;
        width: 215px;
        margin-left: 25px;
        left: 70px;
      }
      .label {
        float: left;
        font-size: 12px;
        line-height: 14px;
        font-weight: 600;
      }
      .business {
        font-size: 17px;
        line-height: 21px;
        float: left;
        clear: left;
        width: 180px;
      }
    }

    .actions {
      height: 50px;
      border: 0;
      clear: both;
      a {
        .default-transition();
        .border-radius(4px);
        line-height: 30px;
        margin: 10px;
        padding: 0 10px;
        font-size: 13px;
        font-weight: 600;
        color: @color-grey-type;
        border: 1px solid @color-grey-border;
        height: 30px;

        &:hover {
          background-color: white;
        }
      }
      .logout {
        float: right;
      }
      .business {
        float: left;
      }
    }

    .name {
      .center-vertical();
      float: left;
      font-size: 12px;
      line-height: 15px;
      font-weight: 600;
      color: @color-grey-type;
      margin-left: 45px;
      max-width: 169px;
    }

    .bold {
      font-weight: 700;
    }

    li {
      border-bottom: 1px solid @color-grey-border;
    }

    i {
      position: absolute;
      top: -14px;
      right: 20px;
      margin: 0 auto;
      font-size: 22px;

      &.front {
        color: white;
        z-index: 3;
      }

      &.back {
        color: @color-grey-border;
        z-index: 2;
        top: -15px;
      }
    }
  }

  .photo {
    .cover();
    .border-radius(3px);
    background-color: @color-grey-border;
    width: 36px;
    height: 36px;
    margin-top: (@headerHeight - 36) / 2;
    cursor: pointer;
  }
}

// Styles for the checkin header
.checkin-header-button > div {
  .flex-block();
  .flex(1);
}
