// overrides for react bootstrap tooltip Component

// credential summary widget custom tooltips with white background
#tip.credential-progress {
  .tooltip-inner {
    background-color: #fdfdfd;
    color: #242424;
    box-shadow:
      0 13px 18px 0 rgba(0, 0, 0, 0.1),
      0 4px 8px 0 rgba(0, 0, 0, 0.08);
    border-radius: 3px;
  }
  &.in {
    opacity: 1;
  }
  &.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #fdfdfd;
  }
  &.tooltip.top .tooltip-arrow {
    border-top-color: #fdfdfd;
  }
}
