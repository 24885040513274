.pagify-pagination {
  .flex-block();
  .flex(1);
  align-items: center;

  li {
    font-size: 14px;
    color: #333333;

    a {
      display: inline-block;
      width: 30px;
      text-align: center;
    }

    &.selected {
      font-weight: bold;
    }

    &.pagify-next,
    &.pagify-prev {
      a {
        width: inherit;
      }
    }
  }
}
