@import "mixins/_transitions";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.arrow {
  @include button-darken(#fff, 5%);
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 34px;
  background: #ffffff;
  border: 1px solid rgba(151, 151, 151, 0.42);
  border-radius: 4px;
  color: #425bc2;
}
.base {
  user-select: none;
  padding: 8px 10px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
}
.page {
  @include button-darken(#fff, 5%);
  composes: base;
}
.ellipsis {
  composes: base;
}
.pageActive {
  composes: base;
  background: #425bc2;
  color: white;
}
.pageGroup {
  padding: 0 7px;
  display: flex;
  align-items: center;
}
