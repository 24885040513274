@import "mixins/_transitions";

.select {
  @include button-darken(#fff, 5%);
  padding: 3px 2px 3px 7px;
  border: 1px solid rgba(#979797, 0.44);
  border-radius: 4px;
  font-size: 14px;
  color: #454545;
  font-weight: 600;
  min-width: 106px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popoverWrapper {
  :global(.MuiMenuItem-root) {
    padding: 0 24px;
  }
}

.menuItem:global(.MuiMenuItem-root) {
  padding: 0 24px;
  line-height: 32px;
  font-size: 15px;
  font-family: Mulish;
  color: rgba(0, 0, 0, 0.54);

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
