@import "mixins/_material-box-shadows";

@mixin button-transition($style: all, $length: 0.2s, $type: ease-in-out) {
  transition: $style $length $type;
}
@mixin hover-transition($style: all, $length: 0.2s, $type: ease-in-out) {
  transition: $style $length $type;
}
@mixin fade-transition($style: opacity, $length: 0.8s, $type: ease-out) {
  transition: $style $length $type;
}
@mixin link-hover() {
  cursor: pointer;
  opacity: 1;
  @include hover-transition;
  &:hover {
    opacity: 0.6;
  }
}
@mixin button-hover($first: 1, $second: 2) {
  @include shadow($first);
  @include button-transition;
  &:hover {
    @include shadow($second);
    // transform: scale(1.005);
  }
}

@mixin button-darken($baseColor, $intensity: 10%) {
  @include button-transition;
  cursor: pointer;
  &:hover {
    background-color: darken($baseColor, $intensity);
    border-color: darken($baseColor, $intensity);
  }
}
