:global {
  /**
 * Variables and Mixins
 */
  @import "mixins/prefixer.less";
  @import "mixins/mixins.less";
  @import "includes/normalize.less";
  @import "includes/variables.less";
  @import "includes/colors.less";

  /**
 * Plugins
 * Note: Load toward top, so we can overwrite any styles below
 */
  @import "includes/plugins/jquery-ui.less";
  @import "includes/plugins/tag-it.less";
  @import "includes/plugins/messenger.less";
  @import "includes/plugins/react-datepicker.less";
  @import "includes/plugins/pagination.less";
  @import "includes/plugins/react-tags.less";
  @import "includes/plugins/react-checkbox.less";
  @import "includes/plugins/react-checkbox-custom.less";
  @import "includes/plugins/react-select.less";
  @import "includes/plugins/react-select-custom.less";
  @import "includes/plugins/react-tether.less";
  @import "includes/plugins/react-widgets-custom.less";
  @import "includes/plugins/quill-custom.less";
  @import "includes/plugins/react-table.less";
  @import "includes/plugins/react-table-custom.less";
  @import "includes/plugins/react-datetime.less";
  @import "includes/plugins/add-to-calendar.less";
  @import "lennd-wysiwyg.less";
  @import "lennd-attend.less";

  /**
 * Body styles
 */
  body,
  html {
    height: 100%;
    width: 100%;
  }

  body {
    padding: 0;
    background-color: #fbfbfc;
    // Default color
    color: @color-grey-type;

    &.mobile-nav-open {
      position: relative;
      overflow: hidden;

      .base-header-mobile-nav,
      .base-header {
        .transform(translateX(240px));
      }

      .overlay-container {
        .opacity(1);
        pointer-events: auto;
      }
    }

    &.no-scroll {
      overflow: hidden !important;

      .overlay-container {
        .opacity(1);
        pointer-events: auto;
        z-index: 21;
      }
    }

    &.disable-hover {
      pointer-events: none !important;
    }
  }

  /**
 * Root Elements
 */

  html,
  button,
  input,
  select,
  textarea {
    // font-family: proxima-nova, arial, sans-serif;
    font-family:
      Mulish,
      -apple-system,
      system-ui,
      Helvetica Neue,
      Helvetica,
      Arial,
      sans-serif;

    .font-smoothing();
  }

  // remove focus outline from input field
  input,
  textarea,
  select,
  button {
    &:focus {
      outline: 0;
    }
  }

  // remove webkit styling
  textarea,
  select:not([multiple]),
  input[type="text"],
  input[type="email"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  select:not([multiple]) {
    cursor: pointer;
    background-color: #ffffff;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23494949'><polygon points='0,0 100,0 50,50'/></svg>")
      no-repeat;
    background-position: right 10px center;
    background-size: 12px;
  }

  .page-title-404 {
    .font-xlarge();
    text-align: center;
    margin: 150px 0;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0;
    padding: 0;
  }

  a {
    cursor: pointer;
    text-decoration: none;
    color: @color-grey-type;
  }

  :hover,
  :active,
  :focus {
    outline: 0;
  }

  /**
 * Alerts
 */
  .alert {
    margin-bottom: 0;
    padding: 20px;
    background-color: rgba(255, 167, 13, 0.25);
    text-align: center;
  }

  /**
 * jQuery UI Overrides
 */

  .ui-state-disabled {
    .opacity(1) !important;
  }

  /**
 * Base Elements
 */
  #app {
    height: 100%;
  }

  .app-wrapper {
    .flex-block();
    .flex(1);
    height: 100%;
  }

  /*
  Box Sizing
  Apply a natural box layout model to all elements, but allowing components to change
*/
  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  /* Clearfix */
  .clearfix:before,
  .clearfix:after {
    content: " ";
    display: table;
  }

  .clearfix:after {
    clear: both;
  }

  .hide {
    display: none;
  }

  /* Grid */
  .contain {
    position: relative;
    margin: 0 20px;
    clear: both;

    &.grid {
      margin: 0 10px;
    }
  }

  .privacy-policy {
    position: relative;
    height: 100%;
    padding: 30px;
    overflow: auto;

    .row {
      display: flex;
      flex-direction: column;

      ul {
        display: flex;
      }

      li {
        margin-right: 20px;
      }
    }
  }

  @-webkit-keyframes rotating {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  .rotating {
    animation: rotating 2s linear infinite;
  }

  .hoverContainer {
    &:hover .hideOnHover {
      display: none !important;
    }

    .showOnHover {
      display: none !important;
    }

    &:hover .showOnHover {
      display: inherit !important;
    }
  }

  @import "includes/typography.less";
  @import "includes/responsive.less";
  @import "includes/atoms.less";
  @import "includes/shadows.less";
  @import "includes/inputs.less";
  @import "includes/buttons.less";
  @import "includes/scrollbars.less";
  @import "includes/nav.less";
  @import "includes/tooltip.less";
  @import "includes/react-tooltip.less";
  @import "includes/react-tooltip-custom.less";
  @import "includes/drop.less";
  @import "includes/modals-new.less";
  @import "includes/alerts.less";
  @import "includes/cards.less";
  @import "includes/overlays.less";
  @import "includes/datetimepicker.less";
  @import "includes/options-dropdown.less";
  @import "includes/animated-submit-button.less";
  @import "includes/intercom.less";

  /**
 * Sections
 */
  @import "includes/header.less";
  @import "includes/footer.less";

  /**
 * Pages
 */
  @import "includes/settings.less";
  @import "includes/platform.less";

  @import "includes/pages/auth/login.less";
  @import "includes/pages/auth/forgot.less";

  @import "includes/pages/event-tasks.less";
  @import "includes/pages/event-team.less";

  @import "includes/pages/contacts.less";
  @import "includes/pages/join.less";
}
