.modal-wrapper {
  .default-transition();
  opacity: 0;
  pointer-events: none;
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  .flex-block();
  .align-items(center);

  background-image: linear-gradient(
    -135deg,
    #425bc2 0%,
    #425bc2 11%,
    #425bc2 12%,
    #425bc2 12%,
    #425bc2 28%,
    #425bc2 100%
  );

  &.modal-visible {
    opacity: 1;
    pointer-events: auto;
  }
}

.modal-inner {
  .border-radius(8px);
  .flex-block();
  .flex(1);
  .justify-content(center);
  .align-items(center);
  overflow-y: auto;
  width: 480px;
  height: 100%;
}

.modal-content {
  max-width: 480px;
  max-height: 100%;
  .flex-block();
  .flex(1);
  padding: 60px 0;
  > div {
    .flex-block();
    .flex-direction(column);
    .flex(1);
  }
}

.modal-header {
  padding-bottom: 10px;
  //border-bottom: 1px solid @color-grey-border;
}

.modal-footer {
  position: relative;
  background-color: @color-grey-ultra-light;
  padding: 25px 30px;
  border-top: 1px solid @color-grey-border;
}

.modal-body {
  padding: 25px 30px;
  background-color: #fff;
  .border-radius(8px);
  border: 1px solid #cecece;
  .box-shadow(0px 0px 10px 0px rgba(0, 0, 0, 0.13));
  overflow: auto;
  .flex-block();
  .flex-direction(column);
}

.modal-title {
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 42px;
  color: #ffffff;
  line-height: 50px;
}

.modal-heading {
  font-weight: 600;
  font-size: 26px;
  color: @color-grey-mid-dark;
  line-height: 30px;
  margin-bottom: 15px;
}

.modal-text {
  color: @color-grey-mid;
  font-size: 17px;
  line-height: 19px;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 15px;
}

.modal-description {
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  line-height: 19px;

  .link {
    color: @color-emerald;
    cursor: pointer;
    &:hover {
      color: darken(@color-emerald, 10%);
    }
  }
}

.modal-description-big {
  font-weight: 100;
  font-size: 27px;
  color: #ffffff;
  line-height: 32px;
  margin-bottom: 10px;
}

.modal-tip {
  font-size: 16px;
  color: #ffffff;
  line-height: 19px;
  margin-top: 23px;
  padding: 0px 30px;
}

.modal-icon {
  color: @color-emerald-muted;
  width: 35px;
  margin-right: 10px;
  float: left;
  font-size: 28px;
  &.fa-calendar {
    .transform(rotate(-12deg));
  }
}

.modal-icon-wrapper {
  width: ~"calc(100% - 90px)";
  float: left;
}

.modal-split-item {
  .border-radius(20px);
  position: absolute;
  top: -20px;
  left: 50px;
  background-color: white;
  font-size: 21px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: block;
  text-align: center;
  color: @color-grey-mid;
  font-weight: 600;
}

.modal-count {
  font-size: 16px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.6);
}

// Modal - Follow Prompt
.modal-prompt-after-follow-send-message {
  .modal-title {
    font-size: 23px;
  }
}

// Modal - Add Group
.add-event-group-modal,
.edit-event-group-modal {
  .left-options {
    position: relative;
    float: left;
    margin-top: 7px;

    .make-private-label {
      cursor: pointer;
    }

    .make-private-checkbox {
      margin-right: 8px;
    }

    .icon {
      font-size: 14px;
    }
  }
}

// Modal - Add Grid
.form-add-grid-modal {
  .modal-content {
    max-width: 700px;
  }
}

// Modal - Send Approval
.form-send-approval-modal {
  .modal-content {
    max-width: 420px;
  }
}

// Modal - Add Column
.options-modal {
  background: rgba(0, 0, 0, 0.25);
  .modal-inner {
    .align-items(flex-start);
  }
  .modal-content {
    max-width: 311px;
    max-height: inherit;
  }
  .modal-body {
    overflow: inherit;
  }
}

// Modal - Send Form
.form-send-modal {
  .modal-body {
    padding: 0px;
  }
  .modal-content {
    max-width: 690px;
    width: 100%;
  }
  .modal-title {
    font-size: 30px;
    line-height: 30px;
  }
}
// Medium devices (tablets, 48em and up)
@media screen and (min-width: 48em) {
  .form-send-modal {
    .modal-title {
      font-size: 42px;
      line-height: 50px;
    }
  }
}

// FORM STYLES
.input-wrapper {
  margin-bottom: 18px;

  .existing-members-tag-list {
    max-height: 80px;
    overflow-y: auto;
  }

  .existing-members-list {
    overflow-y: auto;
    height: 90px;
    .border-radius(3px);
    width: 100%;
    background-color: white;
    border: 1px solid @color-grey-medium-light;
    font-weight: 600;
    font-size: 15px;
    color: @color-grey-type;
    line-height: 20px;

    &:focus {
      border-color: @color-emerald;
    }
  }

  .existing-members-list-item {
    display: block;
    font-size: 14px;
    width: 100%;
    padding: 3px 8px;
    cursor: pointer;

    .secondary {
      font-weight: normal;
      font-size: 12px;
      color: #7c8784;
      line-height: 14px;
      margin-left: 5px;
    }

    &:hover {
      background-color: @color-emerald;
      color: #fff;
    }
  }

  .members-list {
    background-color: @color-grey-super-light;
    max-height: 94px;
    overflow: auto;
    padding: 7px;
    &.empty {
      padding: 0;
    }
  }

  .members-list-item {
    .border-radius(4px);
    float: left;
    background-color: @color-emerald-ultra-light;
    border: 1px solid @color-emerald;
    margin: 3px;
    padding: 0 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    .name {
      display: block;
      float: left;
    }
    .secondary {
      font-weight: normal;
      font-size: 12px;
      color: #7c8784;
      line-height: 14px;
      margin-left: 5px;
    }
  }

  .members-list-icon,
  .members-list-close {
    font-size: 10px;
    display: block;
    float: left;
    line-height: inherit;
  }

  .members-list-icon {
    margin-right: 10px;
  }

  .members-list-close {
    cursor: pointer;
    margin-left: 10px;
  }
}

// Positioned modal styles
.positioned-modal-wrapper {
  .default-transition();
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow-y: auto;
  color: white;
  opacity: 0;
  pointer-events: none;

  &.modal-visible {
    opacity: 1;
    pointer-events: auto;
  }

  .modal-body {
    background-color: inherit;
    border: 0px;
  }
}

.positioned-modal-wrapper-center {
  background: rgba(53, 42, 73, 0.5);

  .positioned-modal-inner {
    .center-vertical();
    .center-horizontal();
    top: 25%;
    width: 452px;
  }

  .positioned-modal-text {
    font-weight: 600;
    font-size: 16px;
    color: #ffffff;
    line-height: 19px;
  }

  .modal-body {
    padding: 40px 62px 25px 62px;
  }
}

.positioned-modal-inner {
  .default-transition();
  .box-shadow(0px 0px 10px 0px rgba(0, 0, 0, 0.13));
  .border-radius(8px);
  position: absolute;
  background-image: linear-gradient(
    -135deg,
    #425bc2 0%,
    #425bc2 11%,
    #425bc2 12%,
    #425bc2 12%,
    #425bc2 28%,
    #425bc2 100%
  );

  top: 0;
  left: 0;
  width: 320px;
  @media @tablet-wide {
    width: 420px;
  }
  &.arrow-left {
    &::before {
      position: absolute;
      top: 25px;
      left: -24px;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 16px 24px 16px 0;
      border-color: transparent #612bdb transparent transparent;
    }
  }
  &.arrow-below {
    &::before {
      position: absolute;
      top: 100%;
      left: 24px;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 24px 16px 0 16px;
      border-color: #612bdb transparent transparent transparent;
    }
  }

  .action-positive,
  .action-cancel,
  .action-back {
    &:hover {
      color: white;
    }
  }
}

.positioned-modal-title {
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 5px;
  margin-top: 8px;
}

.positioned-modal-text {
  color: #d5c9f2;
  font-weight: 400;
  font-size: 13px;
  margin-top: 9px;
  margin-bottom: 24px;
}

/**
 * Modal: Add credential member
 */
.credential-members-modal {
  .modal-inner {
    .align-items(flex-start);
  }
}

/**
 * Modal: Add event team member
 */
.add-event-team-member-modal {
  .modal-inner {
    .align-items(flex-start);
  }

  .heavy {
    font-weight: 600;
    color: #545454;
  }

  .description {
    font-weight: normal;
    font-size: 16px;
    color: #797979;
    line-height: 18px;
  }

  .search-results-header {
    font-weight: 600;
    font-size: 18px;
    color: #494949;
    line-height: 22px;
    border-bottom: 1px solid #dadada;
    margin-bottom: 5px;
    padding-bottom: 5px;
  }

  .search-results-footer {
    font-weight: 600;
    font-size: 13px;
    color: #494949;
    letter-spacing: 0.3px;
    line-height: 16px;
    border-top: 1px solid #dadada;
    margin-top: 15px;
    padding-top: 20px;
  }

  .search-results-result {
    padding: 10px 5px;
    cursor: pointer;
    position: relative;

    &:hover {
      background: #d1f1e1;
      .border-radius(5px);

      .icon-angle-right {
        display: inline-block;
      }
    }

    .icon-angle-right {
      display: none;
      .center-vertical();
      font-size: 18px;
      color: #494949;
      line-height: 14px;
      right: 14px;
    }

    .photo {
      float: left;
      background-color: #f7f7f7;
      width: 30px;
      height: 30px;
      margin-right: 9px;
      border: 1px solid #d8d8d8;
      .background-size(cover);
      .border-radius(4px);

      &.empty {
        font-weight: 600;
        font-size: 10px;
        color: #666666;
        letter-spacing: 0.6px;
        line-height: 29px;
        text-align: center;
      }
    }
    .info {
      float: left;
    }
    .name {
      font-weight: 600;
      font-size: 18px;
      color: #494949;
      line-height: 22px;
      max-width: 231px;
      .ellipsis();
    }
    .email {
      font-weight: 600;
      font-size: 13px;
      color: #898989;
      line-height: 16px;
    }
  }

  .search-add {
  }

  .search-add-description {
    font-weight: 600;
    font-size: 18px;
    color: #5a6360;
    line-height: 22px;

    .heavy {
      font-weight: bold;
      color: #494949;
    }
  }

  .search-add-field {
    margin-top: 19px;

    .label {
      display: block;
      font-weight: bold;
      font-size: 18px;
      color: #494949;
      line-height: 22px;
      margin-bottom: 7px;
    }

    .add-to-company {
      display: block;
      padding: 5px;
      background-color: #eee;
      font-weight: 600;
      padding: 10px 14px;
      cursor: pointer;
      margin-top: 11px;

      .text {
        float: left;
        width: 240px;
      }
      .input {
        float: right;
        text-align: center;
        margin-top: 14px;
        margin-right: 12px;
      }
    }

    .input-fname,
    .input-lname {
      width: 147px;
    }

    .input-fname {
      float: left;
    }

    .input-lname {
      float: left;
      margin-left: 10px;
    }
  }

  .search-submit {
    .pill();
    width: 100%;
    margin-top: 12px;
    text-align: center;
    font-size: 15px;
  }

  .search-error {
    color: @color-red-dark;
    text-align: center;
    margin-top: 13px;
    font-weight: 600;
  }
}

/**
 * Modal: Upload file to room
 */
.upload-file-modal {
  .file-preview-wrapper {
    text-align: center;

    img {
      max-width: 100%;
      max-height: 160px;
    }
  }
}

/**
 * Modal - Onboard
 */
.event-onboard-modal {
  .onboard-modal-icon {
    font-size: 45px;
  }

  .modal-content {
    max-width: 1023px;
  }
}

/**
 * Modal - Tether
 */
.modal-tether-element {
  z-index: 101;
}

// Modal - ContactProfileMoreInfo

.more-info-modal {
  background: rgba(56, 52, 60, 0.35);
  .modal-body {
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    position: relative;
    padding: 10px 0;
    .material-icons-x {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
    h2,
    h5 {
      margin: 0 35px;
      margin-bottom: 10px;
    }
    h2 {
      font-size: 20px;
    }
    li {
      list-style: none;
      //  padding-right: 35px;
    }
    .heading-wrapper {
      border-bottom: 1px solid #f9f9f9;
      margin-bottom: 20px;
    }
  }
  .modal-content {
    max-width: 500px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .modal-inner > .material-icons {
    display: none;
  }
  .actions-wrapper {
    .action-cancel {
      margin-right: 18px;
      color: #425bc2;
      &:hover {
        opacity: 0.6;
      }
    }
  }
}

.edit-item-modal {
  .modal-body {
    padding: 10px 0;
    border: none;
  }
  .actions-wrapper {
    padding: 10px 25px;
    padding-bottom: 0;
  }
}

.edit-template-modal {
  .modal-body {
    padding: 10px 0;
    border: none;
    display: block;
    overflow: inherit;
    border-radius: 2px;
  }
  .modal-title {
    font-size: 24px;
    line-height: 24px;
  }
  .modal-content {
    max-width: 520px;
    padding: 20px 0;
  }
  .actions-wrapper {
    padding: 10px 25px;
    padding-bottom: 0;
  }
}

.form-send-approval-modal {
  .modal-title {
    font-size: 24px;
    line-height: 24px;
  }
  .modal-body {
    padding: 10px 0;
    border: none;
    border-radius: 2px;
    display: block;
    overflow: inherit;
  }
  .modal-content {
    max-width: 620px;
    padding: 20px 0;
  }
}

// form card modal
.card-edit-modal {
  .modal-body {
    padding: 0;
  }
  .modal-content {
    max-width: 580px;
    padding: 10px;
  }
  .modal-inner > .material-icons {
    display: none;
  }
}

// credential summary modal
.edit-summary-modal {
  background-color: rgba(0, 0, 0, 0.3);
  background-image: none;
  .modal-content {
    max-width: 525px;
  }
  .modal-body {
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    border: none;
  }
}
