.forgot-password-container {
  h1 {
    font-size: 16px;
    text-align: center;
    color: @color-grey-type-dark;
  }

  // &.login-container .form-signin .btn-do {
  //    margin: 10px 0 35px;
  // }

  // &.login-container .form-signin .other-options a {
  //    width: 100%;
  //    margin-top: 0;
  // }

  .alert {
    margin: 0 auto 0;
    display: none;
  }
}
