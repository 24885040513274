.page-join-virtual-user {
  background-image: linear-gradient(
    -135deg,
    #425bc2 0%,
    #425bc2 11%,
    #425bc2 12%,
    #425bc2 12%,
    #425bc2 28%,
    #425bc2 100%
  );
}

.page-join-corner-logo {
  width: 54px;
  height: 54px;
  position: absolute;
  left: 20px;
  top: 16px;
}

.page-join-title {
  font-weight: 600;
  font-size: 42px;
  color: #ffffff;
  line-height: 50px;
  margin-top: 40px;
}

.page-join-subtitle {
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  line-height: 19px;
  margin-bottom: 22px;
}

.page-join-middle {
  width: 369px;
  margin: 0px auto;
}

.page-join-form-wrapper {
  .box-shadow(0px 0px 10px 0px rgba(0, 0, 0, 0.13));
  .border-radius(8px);
  background: #ffffff;

  .page-join-form-wrapper-bottom {
    .border-radius(0px 0px 8px 8px);
    background: rgba(209, 241, 225, 0.55);
  }

  .inner-wrapper {
    padding: 26px 31px;
  }

  .input-wrapper:last-child {
    margin-bottom: 0px;
  }

  .checkbox-wrapper {
    margin-bottom: 18px;
  }

  .checkbox,
  .checkbox-label {
    float: left;
  }

  .checkbox {
    margin-top: 3px;
  }

  .checkbox-label {
    margin: 0px 0px 0px 8px;
    font-weight: normal;
    font-size: 16px;
    color: #000000;
    line-height: 19px;
  }

  .terms-and-conditions-link {
    font-weight: bold;
    color: #00cc83;
  }

  .page-join-submit {
    .pill();
    width: 220px;
    height: 40px;
    line-height: 12px;
    font-size: 15px;
    text-align: center;
  }
}
