.container {
  padding: 14px 21px;
  font-size: 12px;
  color: #494949;
  display: flex;
  align-items: center;
  flex: 1;
  background-color: white;
  justify-content: space-between;
}
