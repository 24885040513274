.forgot-password {
  .login-title {
    margin-bottom: 0px;
  }
}

.reset-password {
  .login-title {
    margin-bottom: 0px;
    font-size: 30px;
  }
}

.login {
  background: @color-grey-light;
  @media @tablet {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.login-form {
  .box-shadow(0 0 4px 0 rgba(0,0,0,0.5));
  width: 100%;
  margin: 0 auto;
  background-color: white;
  padding: 30px 0 0 0;
  position: relative;
  @media @tablet {
    max-width: 410px;
  }
}

.login-inner {
  padding: 0 50px;
}

.login-message {
  background-color: rgb(255, 255, 199);
  margin: 20px;
  padding: 20px;

  a {
    color: @color-emerald;
  }
}

.login-title {
  .font-large();
  line-height: 1;
  margin-bottom: 30px;
  color: @color-grey-type-dark;
}

.header-login-button {
  .pill(40px);
}

.login-button {
  .pill();
  float: right;
}

.form-separator {
  padding: 30px 0;
  position: relative;
  text-align: center;
  color: @color-grey-medium;
  span {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    padding: 0 20px;
    display: inline-block;
    background-color: white;
    position: relative;
    z-index: 1;
  }
  &::before {
    .center-vertical();
    content: "";
    height: 1px;
    background-color: @color-grey-light;
    display: block;
    width: 100%;
    left: 0;
  }
}

.login-footer {
  padding: 30px 50px;
  background-color: @color-grey-super-light;
  border-top: 1px solid @color-grey-mid-light;
  margin-top: 25px;
}

.login-options {
  @media @phablet {
    float: left;
  }
}

.login-password,
.login-signup {
  .default-transition();
  color: @color-grey-type;
  font-weight: 600;
  display: block;
  line-height: 25px;
  &:hover {
    color: @color-grey-type-dark;
  }
}
