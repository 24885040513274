.options-dropdown {
  .border-radius(14px);
  .box-shadow(inset 0 0 0 1px @color-grey-light);
  display: block;
  margin-left: 15px;
  width: 29px;
  height: 29px;
  text-align: center;
  // cursor: pointer;
  position: relative;
  z-index: 1;

  &.bare {
    margin: 0px;
    padding: 0px;
    border: 0px;
    .border-radius(0px);
    width: auto;
    height: auto;
    float: none;
    .box-shadow(none);

    &:hover,
    &.open {
      .box-shadow(none);
    }
  }

  &.open {
    .options-dropdown-inner {
      display: block;
    }
  }

  &:hover,
  &.open {
    .box-shadow(inset 0 0 0 1px @color-grey-mid-light);

    .icon {
      color: @color-grey-mid-light;
    }
  }

  .icon {
    .user-select(none);
    display: block;
    font-size: 5px;
    line-height: 28px;
    width: 28px;
    color: @color-grey-light;
  }
}

.options-dropdown-inner {
  .border-radius(8px);
  display: none;
  border: 1px solid @color-grey-medium-light;
  position: absolute;
  z-index: 2;
  top: 41px;
  left: -67px;
  background-color: white;
  white-space: nowrap;
  text-align: left;
  width: 165px;
}

.options-dropdown-wrapper {
  padding: 10px 0;
  border-bottom: 1px solid @color-grey-medium-light;
  &:last-of-type {
    border: 0;
  }
}

.options-dropdown-item {
  padding: 10px 20px;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  color: #545454;

  &:hover {
    color: @color-grey-type-dark;
  }
}

.options-dropdown-arrow {
  &::before,
  &::after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 11px 13px 11px;
    position: absolute;
    top: -13px;
    left: 69px;
    border-color: transparent transparent @color-grey-medium-light transparent;
    z-index: 2;
  }
  &::after {
    border-color: transparent transparent white transparent;
    top: -11px;
    z-index: 3;
  }
}
