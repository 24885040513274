footer {
  background: white;
  .box-shadow(0 1px 8px 0 rgba(0,0,0,0.13));
  font-weight: normal;
  color: @color-grey-mid-dark;

  @media @mobile {
    height: auto;
    padding-bottom: 40px;
    text-align: center;
  }

  .left {
    float: left;
    margin-left: 90px;

    @media screen and (max-width: 1140px) {
      margin-left: 0;
    }

    @media @mobile {
      margin-right: 0;
      float: none;
    }
  }

  .right {
    float: right;
    margin-right: 30px;
    margin-bottom: 30px;

    @media screen and (max-width: 1140px) {
      margin-right: 0;
    }

    @media @mobile {
      margin-right: 0;
      float: none;
    }
  }

  .col {
    display: inline-block;
    vertical-align: top;
    margin-right: 60px;

    &:last-child {
      margin-right: 0;
    }

    @media @mobile {
      margin-right: 0;
    }
  }

  .title {
    font-weight: 600;
    font-size: 15px;
    color: @color-grey-mid-dark;
    line-height: 23px;
    margin-top: 40px;

    @media @mobile {
      margin-top: 20px;
    }
  }

  li {
    a {
      display: block;
      font-weight: normal;
      font-size: 15px;
      color: @color-grey-mid-dark;
      line-height: 20px;

      &:hover {
        color: @color-grey-type-dark;
      }
    }
  }

  .logo {
    margin-right: 89px;
    margin-top: 50px;

    @media @mobile {
      margin-top: 30px;
      margin-right: 0;
      text-align: center;
    }

    img {
      @media @mobile {
        width: 80px;
        height: auto;
      }
    }
  }

  .about {
    width: 318px;
    font-size: 14px;
    color: @color-grey-type-dark;
    letter-spacing: 0.12px;
    line-height: 17px;

    @media @mobile {
      width: 100%;
    }
  }

  .links-lennd {
    @media @mobile {
      width: 50%;
    }
  }

  .links-social {
    @media @mobile {
      width: 50%;
    }
  }
}
