// PAGES

/**************
*
*
*    platform-body-page
*
*
***************/

/**************
*  *
*  *
*  *  platform-body-subpage
*  *
*  *
***************/

/**************
*  *
*  *
*  <--- platform-sidebar
*  *
*  *
***************/

/**************
*  *
*  ************
*  *
*  *     ^--- platform-toolbar-top
*  *
***************/

// SUB SECTIONS

/**************
*  *    *
*  *    *
*  *   <--- platform-body-sub-sidebar
*  *    *
*  *    *
***************/

/**************
*  *    * <--- platform-body-sub-sidebar-toolbar
*  ******
*  *    *
*  *    *
*  *    *
***************/

@platformSidebarItemHeight: 55px;

// Platform global elements
.platform-modal {
  .box-shadow(fade(@color-grey-light, 20%) 0 3px 5px);
  .border-radius(3px);
  background-color: white;
  width: 340px;
  z-index: 100;
  border: 1px solid @color-grey-border;
}

.platform-triangle-above,
.platform-triangle-below {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  z-index: 1;
  &.down {
    border-width: 12px 12px 0 12px;
    border-color: @color-grey-border transparent transparent transparent;
  }
  &.left {
    border-width: 13px 14px 13px 0;
    border-color: transparent @color-grey-border transparent transparent;
  }
  &.up {
    border-width: 0 13px 14px 13px;
    border-color: transparent transparent @color-grey-border transparent;
  }
}

.platform-triangle-above {
  z-index: 2;
  &.left {
    .transform(translateX(2px));
    border-color: transparent white transparent transparent;
  }
  &.up {
    .transform(translateY(2px));
    border-color: transparent transparent white transparent;
  }
  &.down {
    .transform(translateY(-2px));
    border-color: white transparent transparent transparent;
  }
}
// Platform Elements
.platform-body {
  .flex-block();
  .flex(1);
  overflow: auto;
}

.platform-body-page {
  .flex-block();
  .flex(1);
  .flex-direction(column);
  overflow: auto;
}

.platform-body-subpage {
  .flex-block();
  .flex(1);
  .justify-content(center);
  overflow: auto;

  &.public {
    top: 0px;
    padding: 0px;
  }

  &.padding-top {
    padding-top: 20px;
  }
}

.page-event-overview,
.page-event-profile-about-edit {
  padding-top: 20px;
}

.platform-body-sub-sidebar {
  &.style-nav {
    ul {
      width: 100%;
    }
    li {
      border-bottom: 1px solid @color-grey-super-light;
      padding: 20px 0;
      &:hover {
        background-color: @color-grey-super-light;
      }
    }
  }
}

.platform-body-sidebar-messages {
  background: #403971;
}

.platform-body-sub-sidebar-body {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  top: 0;
  overflow: auto;
  background-color: white;
  z-index: 1;
}

.platform-body-sub-sidebar-toolbar {
  .flex-block();
  .align-items(center);
  width: 100%;
  background-color: white;
  border-bottom: 1px solid @color-grey-border;

  .toggle {
    cursor: pointer;
    height: 100%;
    width: 50%;
    display: inline-block;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    color: @color-grey-mid-dark;
    line-height: 65px;
    .user-select(none);
    position: relative;

    &.unread {
      font-weight: 600;

      &::before {
        left: 3px;
        height: 8px;
        width: 8px;
      }
    }

    &.active {
      border-bottom: 4px solid @color-emerald;
    }
  }
}

.platform-loading {
  .flex(1);
  margin-top: 40px;
  font-size: 32px;
  color: @color-emerald;
  text-align: center;
  line-height: 50px;
}

.platform-wrapper {
  .default-transition();
  .flex-block();
  position: relative;
  z-index: 20;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

// TOP TOOLBAR
.platform-toolbar-top {
  .box-shadow(1px 1px 5px 3px rgba(22, 21, 24, 0.18));
  .user-select(none);
  .flex-block();
  .flex-direction(row);
  height: 45px;
  position: relative;
  background-color: #3c4341;
  background-image: linear-gradient(
    -135deg,
    #425bc2 0%,
    #425bc2 11%,
    #425bc2 12%,
    #425bc2 12%,
    #425bc2 28%,
    #425bc2 100%
  );
  z-index: 5;

  .saved-state {
    position: fixed;
    top: 15px;
    right: 20px;
    font-size: 13px;
    color: @color-emerald-muted;

    &.saving {
      .saved-state-icon,
      .saved-state-text {
        display: none;
      }
      .saved-state-icon-saving,
      .saved-state-text-saving {
        display: inline-block;
      }
    }
  }

  .saved-state-icon {
    font-size: 12px;
  }

  .saved-state-icon-saving {
    display: none;
  }

  .saved-state-text-saving {
    display: none;
    margin-left: 5px;
  }

  .saved-state-text {
    display: inline-block;
    margin-left: 5px;
  }
}

.platform-toolbar-title {
  font-size: 21px;
  font-weight: normal;
  color: #d8d3f4;
  margin-top: 10px;
  float: right;
  display: block;
  margin-right: 19px;
  max-width: 500px;
  line-height: 25px;

  .ellipsis();

  &.long {
    font-size: 20px;
  }
}

.platform-toolbar-title-icon {
  font-size: 9px;
  float: left;
  display: block;
  margin-top: 16px;
  line-height: 38px;
  margin-left: 15px;
}

.platform-toolbar-link-button {
  .pill(32px);
  margin-top: 7px;

  &.small {
    line-height: 9px;
  }
}

.platform-toolbar-add-event-wrapper {
  position: relative;
  float: left;
}

// LEFT SIDEBAR
.platform-sidebar {
  .box-shadow(2px 0 9px 0 rgba(0, 0, 0, 0.1));
  .user-select(none);
  height: 100%;
  width: 55px;
  background-color: #312f34;
}

.platform-sidebar-icon,
.platform-sidebar-icon-up,
.platform-sidebar-icon-collapse {
  font-size: 16px;
  display: block;
  width: 55px;
  text-align: center;
  line-height: @platformSidebarItemHeight;
  position: relative;
  z-index: 2;
}

.platform-sidebar-link-group {
  border-style: solid;
  border-color: @color-grey-type;
  border-width: 1px 0 0 0;
}

.platform-sidebar-label {
  .box-shadow-dark();
  display: none;
  position: absolute;
  z-index: 2;
  left: 100%;
  background-color: white;
  color: black;
  white-space: nowrap;
  top: 0;
  padding: 0 20px;
  &::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 13px 13px 13px 0;
    border-color: transparent #ffffff transparent transparent;
    position: absolute;
    left: -13px;
    top: 14px;
  }
}

.platform-sidebar-icon-collapse {
  .transform(scaleX(-1));
  font-size: 12px;
}

.platform-sidebar-link {
}

.platform-sidebar-label {
}

.platform-sidebar-menu-events {
  padding: 0px;
  width: 255px;
  height: 329px;
  background-color: #fafafa;
  z-index: 99;
  .box-shadow(0px 2px 13px 0px rgba(0, 0, 0, 0.3));
}

.platform-sidebar-menu-events-title {
  font-weight: normal;
  font-size: 22px;
  color: #494949;
  line-height: 26px;
  height: 54px;
  border-bottom: 1px solid #e7e7e7;
  padding-left: 15px;
  background-color: #fff;
  line-height: 54px;
}

.platform-sidebar-menu-events-title-add {
  background: #00cc83;
  width: 29px;
  height: 29px;
  float: right;
  margin-right: 15px;
  margin-top: 13px;
  text-align: center;
  color: #fff;
  font-size: 24px;
  line-height: 30px;
  cursor: pointer;
  .border-radius(100px);

  &:hover {
    background: darken(#00cc83, 10%);
  }
}

.platform-sidebar-menu-events-list {
  overflow-y: scroll;
  height: 275px;
}

.platform-sidebar-menu-events-list-item {
  padding-left: 22px;
  height: 35px;
  cursor: pointer;
  padding-right: 10px;
  .flex-block();
  .align-items(center);

  &.has-unread {
    padding-left: 10px;
    .platform-sidebar-menu-events-list-item-unread {
      display: block;
    }
  }

  &.active {
    background-color: #ddd;
  }

  &:hover {
    background-color: #e7e7e7;
    .platform-sidebar-menu-events-list-item-tasks,
    .platform-sidebar-menu-events-list-item-messages {
      display: block;
    }
  }
}

.platform-sidebar-menu-events-list-item-unread {
  display: none;
  float: left;
  width: 8px;
  height: 8px;
  background: #00cc83;
  margin-right: 5px;
  .border-radius(8px);
}

.platform-sidebar-menu-events-list-item-name {
  float: left;
  font-size: 15px;
  color: #545454;
  .ellipsis();
  .flex(1);
  .align-items(center);
  .flex-block();
}

.platform-sidebar-menu-events-list-item-tasks,
.platform-sidebar-menu-events-list-item-messages {
  display: none;
  position: relative;
  float: right;
  background-color: #d8d8d8;
  width: 35px;
  text-align: center;
  height: 100%;

  .unread {
    display: none;
    font-weight: 600;
    font-size: 10px;
    color: #ffffff;
    letter-spacing: 0.6px;
    line-height: 12px;
    background: #00cc83;
    .border-radius(2px);
    padding: 2px 4px;
    position: absolute;
    right: 2px;
    top: 2px;
  }

  &.has-unread {
    .unread {
      display: block;
    }
  }

  &:hover {
    background-color: #b7b7b7;
  }
}

.platform-sidebar-menu-events-list-item-tasks {
  line-height: 51px;
}

.platform-sidebar-menu-events-list-item-messages {
  line-height: 47px;
}

.platform-sidebar-link-events-link {
  color: @color-emerald-muted;
  line-height: @platformSidebarItemHeight;
  display: block;
  position: relative;
  cursor: pointer;
  position: relative;

  &:hover {
    color: white;
  }

  &.has-notification {
    .messages-count {
      display: block;
    }
  }
}

.platform-sidebar-link-events {
  position: relative;

  &.active {
    color: white;
    font-weight: 600;

    .platform-sidebar-link-events::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 4px;
      background-color: @color-emerald;
    }
  }

  &:hover {
    .platform-sidebar-label {
      display: block;
    }
  }
}

.platform-sidebar-link,
.platform-sidebar-link-search {
  color: @color-emerald-muted;
  line-height: @platformSidebarItemHeight;
  display: block;
  position: relative;
  cursor: pointer;

  &.active {
    color: white;
    font-weight: 600;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 4px;
      background-color: @color-emerald;
    }
  }

  &:hover {
    color: white;
    .platform-sidebar-label {
      display: block;
    }
  }

  &.has-notification {
    .messages-count {
      display: block;
    }
  }
}

.platform-sidebar-link-search {
  /*&::before {
    .border-radius(3px);
    background-color: @color-grey-super-light;
    border: 1px solid @color-grey-border;
    display: block;
    content: "";
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    z-index: 1;
  }*/
}

.platform-sidebar-footer {
  position: absolute;
  bottom: 0;
  left: 0;
}

.platform-sidebar-profile-group {
  background-color: #161518;
  cursor: pointer;
  border-color: #666666;
  color: #b8c1bf;
  padding: 10px;

  &:hover {
    color: white;
  }
}

.platform-sidebar-profile-photo {
  .border-radius(3px);
  width: 35px;
  height: 35px;
  background-size: cover;
  background-position: center;
  margin: 10px;
  float: left;
  display: block;
  text-align: center;
  line-height: 35px;
  background-color: @color-grey-type-dark;
}

.platform-profile-menu {
  position: fixed;
  top: 9px;
  left: 65px;
  font-size: 13px;
  font-weight: 600;

  .platform-triangle-wrapper {
    position: absolute;
    top: 6px;
    left: -12px;
  }

  .photo {
    .border-radius(3px);
    .cover();
    background-color: @color-grey-border;
    width: 36px;
    height: 36px;
    background-position: center;
    margin-top: (@headerHeight - 36) / 2;
    cursor: pointer;
  }

  .profile {
    background-color: white;
    padding: 20px 0;
    border-color: @color-grey-light;

    .inner {
      margin: 0px 20px;
    }

    .photo {
      .border-radius(4px);
      margin-top: 0;
      float: left;
      width: 73px;
      height: 73px;
      font-size: 49px;
      text-align: center;
      line-height: 77px;
      font-weight: 100;
    }
    .content {
      margin-left: 20px;
      float: left;
      font-weight: 600;
      color: @color-grey-type;
      width: 202px;
    }
    .active-company {
      font-size: 12px;
      color: @color-emerald;
    }
    .business {
      font-size: 18px;
      line-height: 22px;
      float: left;
      clear: left;
      margin-bottom: 5px;
    }
    a {
      float: left;
      clear: left;
    }
    .profile-button {
      .button(4px, 30px, 11px, capitalize);
      float: left;
      clear: left;
    }
    .edit {
      float: left;
      color: #a3a3a3;
      clear: none;
      margin-left: 15px;
    }
  }

  .logged-in-as {
    .flex-block();
    position: relative;
    width: 100%;
    padding: 13px 16px;
    line-height: 15px;
    color: #5d5d5d;

    .photo {
      margin-top: 0px;
      .border-radius(100px);
      width: 54px;
      height: 54px;
      float: left;
      cursor: default;
    }
    .info {
      float: left;
      margin-left: 10px;
    }
    .name {
      font-size: 17px;
      line-height: 21px;
      font-weight: 600;
      margin-top: 6px;
    }
    .edit {
      color: @color-emerald;
      line-height: 19px;
      clear: none;
      &:hover {
        color: darken(@color-emerald, @darkenPercentage);
      }
      .transition(color 0.2s ease-in-out);
      font-size: 12px;
      cursor: pointer;
    }
  }

  .switch {
    max-height: 137px;
    overflow: auto;
    a {
      .transition(background-color 0.2s ease-in-out);
      position: relative;
      padding: 15px 20px;
      width: 100%;
      display: block;
      border-bottom: 1px solid @color-grey-border;
      background-color: @color-grey-super-light;

      &:last-child {
        border-bottom: 0;
      }

      &:hover {
        background-color: white;
      }
    }
    .photo {
      .border-radius(4px);
      height: 50px;
      width: 50px;
      margin-top: 0;
    }
    .icon-switch {
      .center-vertical();
      right: 5px;
      color: @color-grey-mid-light;
      font-size: 11px;
    }
    .content {
      .center-vertical();
      display: block;
      float: left;
      width: 215px;
      margin-left: 25px;
      left: 70px;
    }
    .label {
      float: left;
      font-size: 12px;
      line-height: 14px;
      font-weight: 600;
    }
    .business {
      font-size: 17px;
      line-height: 21px;
      float: left;
      clear: left;
      width: 180px;
    }
  }
  .actions {
    height: 50px;
    border: 0;
    clear: both;
    background-color: @color-grey-super-light;
    a {
      .default-transition();
      .border-radius(4px);
      line-height: 30px;
      margin: 10px;
      padding: 0 10px;
      font-size: 13px;
      font-weight: 600;
      border: 1px solid @color-grey-border;
      height: 30px;

      &:hover {
        background-color: white;
      }
    }
    .logout {
      float: right;
    }
    .business {
      float: left;
    }
  }

  .edit {
    .transition(color 0.2s ease-in-out);
    font-size: 12px;
    line-height: 30px;
    color: @color-emerald;
    cursor: pointer;
    &:hover {
      color: darken(@color-emerald, @darkenPercentage);
    }
  }

  .bold {
    font-weight: 700;
  }

  li {
    border-top: 1px solid @color-grey-border;
  }

  i {
    position: absolute;
    top: -14px;
    right: 20px;
    margin: 0 auto;
    font-size: 22px;

    &.front {
      color: white;
      z-index: 3;
    }

    &.back {
      color: @color-grey-border;
      z-index: 2;
      top: -15px;
    }
  }
}

.platform-search-input {
  // remove these important rules and replace them with something more robust
  line-height: 38px !important;
  height: 38px !important;
  margin-bottom: 0;
  margin-top: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 9px;
  width: 460px !important;
}

// GENERAL
.fa-home {
  font-size: 22px;
}

.icon-titled {
  .transform(rotate(-12deg));
}

.empty-state {
  text-align: center;
  margin: 100px 20px 0 20px;

  a {
    color: @color-grey-medium;
    border-bottom: 1px solid @color-grey-medium;

    &:hover {
      color: @color-emerald;
    }
  }

  .icon {
    display: block;
    text-align: center;
    font-size: 140px;
    color: @color-grey-border;
    margin-bottom: 20px;
  }

  .description {
    font-size: 30px;
    color: @color-grey-medium;
  }
}

.messages-count,
.activity-count {
  display: none;
  .border-radius(10px);
  position: absolute;
  right: 5px;
  top: 15px;
  height: 15px;
  line-height: 15px;
  font-size: 12px;
  font-weight: 700;
  color: white;
  padding: 0 7px;
  z-index: 2;
}
.messages-count {
  background-color: @color-emerald;
}
.activity-count {
  background-color: @color-orange;
}

.platform-header-avatar {
  z-index: 11;
  .options-dropdown-arrow::before,
  .options-dropdown-arrow::after {
    left: 135px;
  }
}
.platform-header-notifications-button {
  .options-dropdown-arrow::before,
  .options-dropdown-arrow::after {
    left: 109px;
  }
  .options-dropdown-arrow::after {
    border-color: transparent transparent #eeeeee transparent;
  }
  .gm-scroll-view {
    padding-bottom: 15px;
  }
}
