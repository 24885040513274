/**
* A stylesheet to manage all the atomic elements
*/

.settings-my-account {
  padding: 30px 0;

  // These styles are non standard, see if we can remove them and make the rest of the page look better
  label {
    font-size: 13px;
    color: @color-grey-medium;
  }

  .photo-wrapper {
    .border-radius(5px);
    .cover();
    width: 100%;
    padding-bottom: 100%;
    color: white;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    position: relative;
    overflow: hidden;

    &.empty {
      .actions {
        display: block;
      }
    }

    &:hover {
      .actions {
        display: block;
      }
    }

    a {
      .center();
      width: 100%;
      color: white;
    }
  }

  .actions {
    .border-radius(5px);
    display: none;
    background-color: rgba(52, 70, 65, 0.79);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }
}

.settings-title {
  font-size: 15px;
  font-weight: 600;
  color: @color-grey-type-dark;
  line-height: 30px;
}

.settings-intro {
  font-size: 16px;
  margin-bottom: 35px;
}

.settings-nav {
  position: fixed;
  width: 175px;
}

.settings-nav-item {
  .ellipsis();
  position: relative;
  border-style: solid;
  border-width: 0 0 0 4px;
  border-color: transparent;
  font-weight: 600;
  font-size: 16px;
  color: @color-grey-type;
  display: block;
  line-height: 45px;
  padding: 0 20px;
  margin-bottom: 10px;
  &.active {
    background-color: @color-grey-ultra-light;
    color: @color-grey-mid-dark;
    font-weight: 700;
    border-color: @color-emerald;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
}

.settings-wrapper {
  .border-radius(0 5px 5px 0);
  width: ~"calc(100% - 175px)";
  float: right;
  background-color: @color-grey-ultra-light;
  padding: 25px 35px;
}

.settings-photos-wrapper {
  margin-bottom: 20px;
}

.settings-profile-photo {
  @media @tablet {
    width: 145px;
    float: left;
    margin-right: 25px;
  }
}

.settings-cover-photo {
  @media @tablet {
    width: ~"calc(100% - 170px)";
    float: left;
  }
}

.settings-cover-photo-wrapper {
  .border-radius(5px);
  .cover();
  text-align: center;
  width: 100%;
  height: 145px;
  font-weight: bold;
  position: relative;
  font-size: 16px;
  overflow: hidden;

  &.empty {
    .actions {
      display: block;
    }
  }

  &:hover {
    .actions {
      display: block;
    }
  }

  a {
    .center();
    width: 100%;
    color: white;
  }
}

.settings-user-item {
  margin-bottom: 25px;
  &.new {
    padding-top: 10px;
  }
  .atom-profile-photo {
    float: left;
    margin-right: 10px;
  }
  .meta {
    float: left;
    width: ~"calc(100% - 60px)";
  }
  .name {
    font-size: 16px;
    font-weight: 700;
    color: @color-grey-type-dark;
    text-transform: capitalize;
  }
  .role {
    font-size: 12px;
    font-weight: 600;
    color: @color-emerald;
    text-transform: capitalize;
    line-height: 20px;
    .icon {
      line-height: 20px;
      color: @color-grey-medium;
      margin-left: 5px;
      display: none; // This should be removed when user roles are implemented
    }
  }
  .description {
    font-size: 14px;
    font-weight: 400;
    color: @color-grey-type-dark;
  }
  .empty-photo {
    border: 2px dashed @color-grey-light;
    background-color: transparent;
  }
  input[type="text"] {
    margin-bottom: 5px;
  }
}

.settings-user-item-invited {
  .name {
    text-transform: lowercase;
  }
}
