.ui-timepicker-div .ui-widget-header {
  margin-bottom: 8px;
}
.ui-timepicker-div dl {
  text-align: left;
}
.ui-timepicker-div dl dt {
  float: left;
  clear: left;
  padding: 0 0 0 5px;
}
.ui-timepicker-div dl dd {
  margin: 0 10px 10px 40%;
}
.ui-timepicker-div td {
  font-size: 90%;
}
.ui-tpicker-grid-label {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}
.ui-timepicker-div .ui_tpicker_unit_hide {
  display: none;
}

.ui-timepicker-rtl {
  direction: rtl;
}
.ui-timepicker-rtl dl {
  text-align: right;
  padding: 0 5px 0 0;
}
.ui-timepicker-rtl dl dt {
  float: right;
  clear: right;
}
.ui-timepicker-rtl dl dd {
  margin: 0 40% 10px 10px;
}

/* Shortened version style */
.ui-timepicker-div.ui-timepicker-oneLine {
  padding-right: 2px;
}
.ui-timepicker-div.ui-timepicker-oneLine .ui_tpicker_time,
.ui-timepicker-div.ui-timepicker-oneLine dt {
  display: none;
}
.ui-timepicker-div.ui-timepicker-oneLine .ui_tpicker_time_label {
  display: block;
  padding-top: 2px;
  width: 100%;
  font-weight: bold;
  margin-bottom: 6px;
  font-size: 12px;
}
.ui-timepicker-div.ui-timepicker-oneLine dl {
  text-align: right;
}
.ui-timepicker-div.ui-timepicker-oneLine dl dd,
.ui-timepicker-div.ui-timepicker-oneLine dl dd > div {
  display: inline-block;
  margin: 0;
}
.ui-timepicker-div.ui-timepicker-oneLine dl dd.ui_tpicker_minute:before,
.ui-timepicker-div.ui-timepicker-oneLine dl dd.ui_tpicker_second:before {
  content: ":";
  display: inline-block;
}
.ui-timepicker-div.ui-timepicker-oneLine dl dd.ui_tpicker_millisec:before,
.ui-timepicker-div.ui-timepicker-oneLine dl dd.ui_tpicker_microsec:before {
  content: ".";
  display: inline-block;
}
.ui-timepicker-div.ui-timepicker-oneLine .ui_tpicker_unit_hide,
.ui-timepicker-div.ui-timepicker-oneLine .ui_tpicker_unit_hide:before {
  display: none;
}

#ui-datepicker-div,
.ui-datepicker,
.datepicker-wrapper {
  width: auto;
  height: auto;
  font-size: 10px;

  select.ui-datepicker-month,
  select.ui-datepicker-year {
    padding: 0px;
    margin: 0px;
    font-weight: normal;
    font-size: 11px;
    width: 50%;
    height: 30px;
  }

  .ui-widget-header {
    color: #222222;
    font-size: 15px;
    background: none;
    border: 0px;
    font-weight: normal;
  }

  .ui-datepicker .ui-datepicker-title {
    margin: 0 2.3em;
    line-height: 1.5em;
    font-size: 19px;
    text-align: center;
    font-family:
      Mulish,
      -apple-system,
      system-ui,
      Helvetica Neue,
      Helvetica,
      Arial,
      sans-serif;
  }

  .ui-datepicker td {
    border: 0;
    padding: 0px;
  }

  .ui-state-default,
  .ui-widget-content .ui-state-default,
  .ui-widget-header .ui-state-default {
    border: 1px solid #f4f4f4;
    font-weight: normal;
    font-size: 1.55em;
    color: #353838;
    font-family:
      Mulish,
      -apple-system,
      system-ui,
      Helvetica Neue,
      Helvetica,
      Arial,
      sans-serif;
    background: none;
    &:hover {
      background-color: #f4f4f4;
    }
  }

  .ui-state-hover,
  .ui-datepicker-prev-hover {
    background: none;
    background-color: #f4f4f4;
  }

  .ui-datepicker td span,
  .ui-datepicker td a {
    display: block;
    padding: 0.5em;
    text-align: center;
    text-decoration: none;
  }

  .ui-state-active,
  .ui-widget-content .ui-state-active,
  .ui-widget-header .ui-state-active {
    background: none;
    background-color: @color-emerald;
    color: #fff;

    &:hover {
      background-color: @color-emerald;
      color: #fff;
    }
  }

  .ui-datepicker th {
    padding: 0.7em 0.3em 0.3em 0.3em;
    text-align: center;
    font-family:
      Mulish,
      -apple-system,
      system-ui,
      Helvetica Neue,
      Helvetica,
      Arial,
      sans-serif;
    font-weight: 600;
    font-size: 1.4em;
    border: 0;
  }

  .ui-timepicker-div.ui-timepicker-oneLine dl {
    text-align: left;
  }

  .ui-widget input,
  .ui-widget select,
  .ui-widget textarea,
  .ui-widget button {
    border: 1px solid #e8e8e8;
    width: 100%;
    padding: 0 10px;
    height: 40px;
    letter-spacing: -0.5px;
  }
}
