/**
* A stylesheet to manage all the atomic elements
*/

.atom-photo {
  .cover();
  background-color: @color-grey-border;
  display: block;

  &.square {
    padding-bottom: 100%;
  }

  &.landscape {
    padding-bottom: 56.25%;
  }

  &.portrait {
    padding-bottom: 178%;
  }
}

.atom-profile-photo {
  .border-radius(4px);
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-size: cover;
  background-position: center;
  text-align: center;
  background-color: @color-emerald-ultra-light;
}

.atom-caret-up,
.atom-caret-left,
.atom-caret-right {
  width: 0;
  height: 0;
  border-left: 11px solid rgba(0, 0, 0, 0);
  border-right: 11px solid rgba(0, 0, 0, 0);
  border-bottom: 8px solid;
  display: inline-block;
  margin-left: 2px;
  vertical-align: middle;

  &.atom-caret-left {
    .transform(rotate(-90deg));
  }
  &.atom-caret-right {
    .transform(rotate(90deg));
  }
}
