$white: #fff;
$black: #000000;

$headerBarBackgroundColor: #425cc3;
$headerBarBackBackgroundColor: $headerBarBackgroundColor;

$primary1Color: #425bc2;
$primary2Color: #4a65d5;
$primary3Color: #425bc2;
$primary4Color: #394fa9;
$primary5Color: #748ffc;

$accent1Color: #d0bee4;
$accent2Color: #dfd3ee;
$accent3Color: #f4edfc;
$accent4Color: #a877e1;
$accent5Color: #a88dc7;

$alternateColor: #00cc83;
$alternateColorHover: #00a86c;

$tableTabOff: #6d32b1;
$tableTabOn: #9b5de2;
$tableBackArrow: #c19fe8;
$tableOptionsToolbar: #9b5de2;
$tableOptionsButton: #ac72ec;
$tableOptionsButtonHover: #c492fb;
$tableOptionsButtonIcon: #efe3fd;
$tableToolbarVerticalSeparator: #9063c3;

$inventoryListToggleBackground: #510ee4;
$inventoryListToggleSwitch: #9c69d6;

$tabIconOff: #bca0db;
$tabIconOn: #c099ec;

$placeholderTextColor: #ccc;
$formActiveBorderColor: #6944d1;

// FORM APPROVAL STATUSES
$approvedText: #17a774;
$rejectedText: #d54126;
$hasNotOpenedText: #dc7343;
$openedText: #df822e;
$sentText: $approvedText;
$rejectionSentText: $rejectedText;
$needsApprovalText: $white;

$approvedBg: #daf5eb;
$rejectedBg: #ffe5e1;
$hasNotOpenedBg: rgba(212, 198, 184, 0.3);
$openedBg: #fcf0e4;
$sentBg: $approvedBg;
$rejectionSentBg: $rejectedBg;
$needsApprovalBg: $primary1Color;

$lightGray0: rgba(0, 0, 0, 0.07);
$lightGray: rgba(0, 0, 0, 0.12);
$lightGray1: rgba(0, 0, 0, 0.9);
$gray: #777;
$darkGray: #444;
$darkGray1: #222;

// buttons
$greenButton: #37b24d;
$darkergreenButton: #2e9641;
$redButton: #b23737;
$darkerRedButton: #921818;
$orangeButton: #ff922b;
$orangeHoverButton: #fd7e14;
$grayButtonText: #9f9f9f;
$grayOutlineButton: #f5f3f8;

// gray scale
$gray0: #494949;
$gray1: #666;
$gray2: #888;
$gray3: #aaa;
$gray4: #adadad;
$gray5: #ccc;
$gray6: #e7e6ea;
$gray7: #eee;
$gray8: #666666;
$gray9: #f2f1ef;
