.lennd {
  .Select-arrow-custom {
    color: #b6b3bb;
    display: flex;
    align-items: center;
  }
  .data-destination-select {
    font-size: 14px;

    .Select-placeholder,
    .Select--single > .Select-control .Select-value {
      color: #494949;
    }
  }

  .Select-control {
    min-width: 200px;
    .Select-placeholder,
    .Select--single > .Select-control .Select-value {
      color: #494949;
    }
  }
  .is-focused:not(.is-open) > .Select-control {
    border-color: @color-form-border-active;
    box-shadow: none;
  }

  // form styles, including card view modal
  .page-form,
  .card-edit-modal {
    .Select-control {
      border-radius: 2px;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.09);
    }
    .Select-placeholder {
      font-weight: 600;
      font-size: 14px;
      color: @color-form-placeholder;
    }
    .is-focused > .Select-control {
      border-color: @color-form-border-active;
      box-shadow: none;
    }
    .Select--multi .Select-value-icon,
    .Select--multi .Select-value-label {
      display: inline;
    }
    .Select--single > .Select-control .Select-value,
    .has-value.Select--single
      > .Select-control
      .Select-value
      .Select-value-label,
    .has-value.is-pseudo-focused.Select--single
      > .Select-control
      .Select-value
      .Select-value-label {
      color: #494949;
      font-weight: 600;
    }
  }

  .react-grid-Cell {
    .is-focused > .Select-control {
      border-color: #66afe9;
      box-shadow: none;
    }
    .Select-control {
      border: none;
      border-right: 1px solid #66afe9;
      border-bottom: 1px solid #66afe9;
      border-radius: 0;
      min-width: 150px;
      z-index: 1;
    }
  }

  .react-select-white {
    .Select-control {
      background-color: lighten(#425bc2, 10%);
      border: none;
    }
    .Select-placeholder,
    .Select--single > .Select-control .Select-value {
      color: white;
      font-weight: 600;
      padding-right: 22px;
    }
    .Select-clear-zone {
      display: none;
    }
    .Select-input {
      color: white;
      font-weight: 600;
    }
    .Select-arrow {
      border-color: white transparent transparent;
    }
    .Select-arrow-zone:hover > .Select-arrow {
      border-color: #fafafa transparent transparent;
    }
    .is-open {
      .Select-arrow,
      .Select-arrow-zone:hover > .Select-arrow {
        border-color-top: #fafafa;
      }
      .Select-value-label {
        opacity: 0.5;
      }
    }
    .Select-value-label {
      color: white !important;
      font-weight: 600 !important;
    }
  }
  .Select--multi .Select-value {
    display: table;

    .Select-value-icon,
    .Select-value-label {
      display: table-cell;
    }
  }
}

.filter-select {
  .Select-control {
    border-radius: 0;
    border-color: #979797;
    box-sizing: content-box;
    height: 34px;
  }
  .Select-placeholder,
  .Select--single > .Select-control .Select-value {
    line-height: 34px;
    padding-right: 20px;
  }
  .Select-input {
    height: 34px;
  }
}
