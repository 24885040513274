.react-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
}

.react-tagsinput-tag {
  background-color: #cde69c;
  border-radius: 2px;
  border: 1px solid #a5d24a;
  color: #638421;
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

.react-tagsinput-tag a::before {
  content: " ×";
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: 200px;
}

// Form Send Modal Overrrides
.react-tagsinput {
  background-color: inherit;
  border: 0px;
  overflow: hidden;
  padding: 0px 0px 0px 0px;
}

.react-tagsinput-tag {
  background-color: #e1dee8;
  border-radius: 2px;
  border: 1px solid #cdcad4;
  color: #817b90;
  font-size: 15px;
}

.react-tagsinput-input {
  font-size: 15px;
}
