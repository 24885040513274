.animated-button {
  position: relative;
  transition: 0.2s all ease;
  .button {
  }
  .text {
    flex: 1;
    opacity: 1;
  }
  .success {
    transform: scale(0);
    position: absolute;
    top: 8px;
    right: 8px;
    .material-icons {
      font-size: 30px;
      line-height: 37px;
      margin-left: 3px;
    }
  }
  .fill {
    background-color: #855ef3;
    border-radius: 50px;
    transition: 2s all ease;
    width: 0%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
    height: 100%;
  }
}
.animated-button-orange {
  .fill {
    background-color: rgb(255, 159, 37);
  }
}
.animated-button.animate {
  background-color: #ccc;
  height: 15px;
  border-radius: 50px;
  margin-top: 22px;
  .text {
    opacity: 0;
    width: 0;
  }
  .button {
  }
  .fill {
    width: 100%;
    transition: 2s all ease 0.6s;
  }
  &:hover {
    background-color: #ccc;
  }
}
.animated-button.success {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  .fill {
    width: 100%;
  }
  .text {
    opacity: 0;
    width: 0;
  }
  .button {
  }
  .success {
    z-index: 3;
    transform: scale(1);
    transition: 0.3s all ease 0.3s;
  }
  &:hover {
    width: 50px;
    height: 50px;
  }
}
