.event-team {
  padding: 25px 39px 45px 39px;
  overflow: auto;
}

.event-team-tabs {
  display: -webkit-flex;
  .flex-block();
  -webkit-flex-direction: row;
  .flex-direction(row);

  .border-bottom-gray {
    border-bottom: 1px solid #cfcfcf;
  }

  .border-bottom-white {
    border-bottom: 1px solid #ffffff;
  }
}

.event-team-tabs-filler {
  .flex(1);
}

.event-team-tabs-tab {
  font-weight: 600;
  font-size: 21px;
  color: #1ea575;
  line-height: 25px;
  padding: 19px 41px;
  cursor: pointer;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;
  border-bottom: 1px solid #fff;
  .border-radius(8px 8px 0px 0px);

  &:hover {
    background-color: #eee;
  }

  &.active {
    border-left-color: #cfcfcf;
    border-right-color: #cfcfcf;
    border-top-color: #cfcfcf;
    background-color: #ffffff;
    color: #494949;

    &:hover {
      background-color: #ffffff;
    }
  }

  .label {
  }

  .count {
    font-weight: normal;
    margin-left: 5px;
  }
}

.event-team-controls {
}

.event-team-controls-sort {
  float: left;

  label {
  }
  .label {
    font-weight: 600;
    font-size: 18px;
    color: #494949;
    line-height: 22px;
    margin-right: 15px;
    display: inline-block;
    vertical-align: middle;
  }
  .dropdown {
    width: 230px;
    display: inline-block;
    vertical-align: middle;
  }
}

.event-team-controls-add {
  .pill();
  line-height: 18px;
  height: 48px;
  float: right;
  font-size: 15px;
  color: #fff;
  width: 253px;
  text-align: center;
  .border-radius(26px);

  .icon {
  }
}

.event-team-members {
  background-color: #fff;
  padding: 0px 0px;
  border-left: 1px solid #cfcfcf;
  border-right: 1px solid #cfcfcf;
  border-bottom: 1px solid #cfcfcf;
  .border-radius(0px 0px 8px 8px);
}

.event-team-members-member-inner {
  padding: 17px 37px;
}

.event-team-members-member {
  border-bottom: 1px solid #cfcfcf;

  &:last-child {
    border-bottom: 0px;

    .options {
      .border-radius(0px 0px 8px 8px);
    }
  }

  .photo {
    float: left;
    background-color: #eee;
    width: 48px;
    height: 48px;
    margin-right: 14px;
    border: 1px solid @color-grey-mid-light;
    .background-size(cover);
    .border-radius(8px);

    &.empty {
      background-color: #fff;
      font-weight: 600;
      text-align: center;
      line-height: 46px;
    }
  }
  .info {
    float: left;
  }
  .name {
    font-weight: bold;
    font-size: 24px;
    color: #494949;
    line-height: 26px;
  }
  .about {
    font-size: 17px;
    color: #a7a7a7;
    line-height: 26px;
  }
  .tasks {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .permission {
    float: right;
    font-size: 20px;
    color: #a7a7a7;
    line-height: 24px;
    cursor: pointer;
    margin-top: 17px;

    &:hover {
      color: darken(#a7a7a7, 10%);
    }

    .icon {
      font-size: 14px;
      color: #00cc83;
      line-height: 14px;
      margin-left: 6px;
    }

    .icon-chevron-down {
      display: none;
    }
  }
  .options {
    clear: both;
    padding: 10px 37px;
    margin-top: 13px;
    background: #f7f7f7;
  }
  .option {
    font-size: 14px;
    color: #a7a7a7;
    line-height: 17px;
    float: right;
    cursor: pointer;
    text-decoration: underline;

    &.option-no-link {
      text-decoration: none;
      cursor: default;
    }

    &:not(.option-no-link):hover {
      color: darken(#a7a7a7, 15%);
    }
  }

  .option-sep {
    float: right;
    margin: 0 8px;
    font-size: 17px;
    color: #a7a7a7;
    line-height: 16px;
  }

  .option-primary {
    font-weight: 600;
    color: #1ea575;

    &:hover {
      color: darken(#1ea575, 10%);
    }
  }
}
