.event-card {
  .user-select(none);
  width: 100%;
  padding-bottom: 20px;
  @media @phablet {
    float: left;
    width: 50%;
  }
  @media @tablet-wide {
    width: ~"calc(100%/3)";
  }
  &.empty {
    .photo {
      background-color: @color-emerald-ultra-light;
    }
  }
  .flag-draft {
    display: none;
    position: absolute;
    left: 18px;
    top: 17px;
    letter-spacing: 0.09em;
    padding: 5px 20px;
    text-align: center;
    text-transform: uppercase;
    color: white;
    background-color: rgba(90, 90, 90, 0.6);
    z-index: 1;
    font-weight: 400;
    .border-radius(3px);
    font-size: 10px;
    .transition(opacity 0.2s 0.3s ease-in-out);
  }
  .photo {
    .cover();
    .border-radius(5px 5px 0 0);
    height: 202px;
    background-color: @color-grey-light;
    position: relative;
    @media @mobile {
      height: 162px;
    }
    .empty-text {
      font-size: 65px;
      font-weight: 100;
      text-align: center;
      line-height: 195px;
      color: white;
      display: block;
    }
    .filter {
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: fade(black, 60%);
      .border-radius(5px 5px 0 0);
      .opacity(0);
      .transition(opacity 0.2s ease-in-out);
      .date {
        position: absolute;
        left: 21px;
        top: 21px;
        color: white;
        font-size: 14px;
        font-weight: 600;
        @media @mobile {
          top: 17px;
          font-size: 11px;
        }
      }
      .actions {
        position: absolute;
        right: 10px;
        top: 14px;
      }
      .download,
      .share,
      .more {
        color: white;
        .opacity(0.72);
        &:hover {
          .opacity(1);
        }
      }
      .download {
        margin-right: 11px;
      }
      .share {
        margin-right: 11px;
      }
      .more {
        display: inline-block;
        vertical-align: middle;
        i {
          border: 2px solid rgba(255, 255, 255, 0.52);
          padding: 0 3px;
          height: 16px;
          line-height: 18px;
          .border-radius(3px);
        }
        &:hover {
          .more-options {
            display: block;
          }
        }
      }
      .view,
      .edit {
        .pill(32px);
        text-align: center;
        width: ~"calc(50% - 30px)";
        margin: 0 20px 0 10px;
        float: left;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
      .view {
        margin: 0 10px 0 20px;
      }
      .view-only {
        margin: 0px auto;
        float: none;
        left: 0px;
        right: 0px;
        position: absolute;
      }
    }
  }
  .about {
    .default-transition();
    .transform(translateY(40px));
    .border-radius(0 0 5px 5px);
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    overflow: hidden;
    padding: 20px;
  }
  .name {
    color: @color-deep-grey-dark;
    font-size: 23px;
    font-weight: 600;
    margin-bottom: 6px;
    .ellipsis();
    @media @mobile {
      white-space: normal;
      overflow: visible;
      text-overflow: initial;
      font-size: 14px;
    }
  }
  .stats {
    color: @color-deep-grey-light;
    font-size: 14px;
    line-height: 22px;
    @media @mobile {
      font-size: 11px;
    }
    .off {
      display: block;
      float: left;
    }
    .on {
      display: none;
      float: left;
    }
    .message-team {
      .button(4px, 22px, 10px, capitalized);
      margin-left: 10px;
      padding: 0 8px;
      .icon {
        margin-right: 5px;
      }
    }
    span {
      font-weight: 600;
    }
  }
  .collaborators {
    color: @color-deep-grey;
    font-size: 14px;
    .opacity(0);
    .transition(opacity 0.1s ease-in-out);
    padding-top: 14px;
    @media @mobile {
      display: none;
    }
    .title {
      margin-bottom: 14px;
    }
    strong {
      font-weight: 600;
    }
    .circles-wrapper {
      position: relative;
      width: ~"calc(100% - 58px)";
      height: 29px;
      overflow: hidden;
      float: left;
    }
    .circles {
      .default-transition();
      position: absolute;
      left: 0;
      top: 0;
    }
    .circle {
      .cover();
      .border-radius(100px);
      position: relative;
      float: left;
      width: 29px;
      height: 29px;
      background-color: @color-grey-border;
      margin-right: 4px;
      cursor: pointer;
      position: relative;
      text-align: center;
      font-weight: 100;
      color: white;
      line-height: 29px;
      &.empty {
        background-color: @color-emerald-ultra-light;
      }
    }
    .arrows {
      float: left;
      margin-left: 15px;
      line-height: 29px;
    }
    .arrow {
      width: 20px;
      height: 29px;
      background-color: @color-grey-super-light;
      color: @color-deep-grey-light;
      font-size: 15px;
      display: block;
      float: left;
      text-align: center;
      cursor: default;
      .opacity(0.5);
      &.left {
        margin-right: 3px;
      }
      &.active {
        .opacity(1);
        &:hover {
          cursor: pointer;
          color: darken(@color-deep-grey-light, @darkenPercentage);
          background-color: darken(@color-grey-super-light, @darkenPercentage);
        }
      }
    }
  }
  &.draft {
    .flag-draft {
      display: block;
    }
  }
  &:hover {
    .filter {
      .opacity(1);
      .transition-delay(0.1s);
    }
    .stats {
      .off {
        display: none;
      }
      .on {
        display: block;
      }
    }
    .collaborators {
      .opacity(1);
    }
    .flag-draft {
      .opacity(0);
      .transition-delay(0s);
    }
    .about {
      .transform(translateY(0));
    }
  }
  @media @mobile {
    &:hover {
      .collaborators {
        display: none;
      }
      .about {
        height: auto;
      }
      .stats {
        .off {
          display: block;
        }
        .on {
          display: none;
        }
      }
    }
  }
}

.event-card-inner {
  .box-shadow(fade(@color-grey-light, 20%) 0 3px 5px);
  .border-radius(5px);
  margin: 0 10px;
  background-color: white;
  position: relative;
  cursor: pointer;
  height: 237px;
  overflow: hidden;
  @media @phablet {
    height: 307px;
  }
}

.vendor-card {
  .user-select(none);
  .default-transition();
  width: 100%;
  position: relative;
  @media @tablet {
    float: left;
    width: 50%;
  }
  @media @desktop {
    float: left;
    width: ~"calc(100%/3)";
  }
  @media @desktop-wide {
    float: left;
    width: 25%;
  }
  &.disable-click {
    cursor: default;
    a:not(.follow-btn) {
      cursor: default;
    }
  }
  &.empty {
    .photo {
      background-color: @color-emerald-ultra-light;
    }
  }
  .photo {
    .cover();
    .box-shadow(inset 0 8px 14px -10px rgba(0, 0, 0, 0.06));
    height: 180px;
    width: 100%;
    background-color: @color-grey-border;
    font-size: 65px;
    font-weight: 100;
    text-align: center;
    line-height: 188px;
    color: white;
    @media @mobile {
      width: 100%;
    }
  }
  .meta-wrapper {
    padding: 14px 17px 0 17px;
    height: 67px;
  }
  .name {
    .font-medium();
    .ellipsis();
    display: block;
    &:hover {
      color: darken(@color-grey-type, @darkenPercentage);
    }
  }
  .roles {
    .font-tiny();
    .ellipsis();
    text-transform: uppercase;
    .role {
      &::before {
        content: ",\0000a0";
      }
      &:first-child:before {
        content: normal;
      }
    }
  }
  .stats-wrapper {
    padding: 0 18px;
    height: 56px;
    text-align: left;
  }
  .events,
  .followers {
    display: inline-block;
    vertical-align: middle;
    margin-top: 13px;
    &:hover {
      .count,
      .label {
        color: darken(@color-grey-medium, @darkenPercentage);
      }
    }
    .count {
      font-size: 12px;
      font-weight: bold;
      color: @color-grey-medium;
    }
    .label {
      font-size: 12px;
      color: @color-grey-medium;
    }
  }
  .events {
    margin-right: 17px;
  }
  .message-me-icon {
    float: right;
    line-height: 16px;
    margin-top: 20px;
    border-right: 1px solid @color-grey-border;
    padding-right: 10px;
    color: @color-grey-medium;
    &.active,
    &:hover {
      color: @color-emerald;
    }
  }
  .follow-btn {
    float: right;
    display: inline-block;
    font-size: 15px;
    line-height: 56px;
    color: @color-emerald;
    font-weight: 400;
    padding: 0 7px 0 13px;
    i {
      display: none !important;
    }
    &.hide {
      display: none;
    }
    &:hover {
      color: darken(@color-grey-mid, @darkenPercentage);
    }
    .following {
      display: none;
    }
    &.following {
      color: @color-grey-mid-light;
      .following {
        display: block;
      }
      .follow {
        display: none;
      }
      .on {
        display: none;
      }
      &:hover {
        color: @color-orange;
        .on {
          display: block;
        }
        .default {
          display: none;
        }
      }
    }
  }
  .options-wrapper {
    display: none;
    position: absolute;
    right: 15px;
    top: 86px;
    z-index: 2;
    width: 30px;
    height: 30px;
    &.open {
      .dropdown-wrapper {
        display: block;
      }
    }
    &:hover {
      .toggle {
        background-color: black;
      }
    }
    .toggle {
      color: white;
      display: inline-block;
      background: @color-grey-type-dark;
      .border-radius(100px);
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 33px;
      font-size: 16px;
      position: absolute;
      z-index: 5;
    }
    .dropdown-wrapper {
      display: none;
      position: absolute;
      top: 39px;
      width: 163px;
      right: -5px;
      i {
        top: -18px;
        font-size: 30px;
        color: white;
        right: 12px;
        position: absolute;
        z-index: 1;
      }
    }
    ul {
      background-color: white;
      border: 1px solid @color-grey-border;
      padding: 8px;
      .border-radius(3px);
      .box-shadow(0 0 2px 0 rgba(0, 0, 0, 0.23));
      a {
        display: block;
        padding: 4px 12px;
        color: @color-grey-mid;
        font-size: 13px;
        &:hover {
          color: @color-grey-type-dark;
        }
      }
    }
  }
  &:hover {
    .options-wrapper {
      display: inline-block;
    }
  }
}

.vendor-card-inner {
  .border-radius(4px);
  background-color: white;
  cursor: pointer;
  margin: 0 10px 20px 10px;
  .box-shadow-light();
  &:hover {
    .box-shadow-dark();
  }
}

.publication-card {
  .user-select(none);
  width: 100%;
  @media @tablet {
    float: left;
    width: 50%;
  }
}

.publication-card-inner {
  .border-radius(20px);
  overflow: hidden;
  border: 1px solid @color-emerald;
  background-color: @color-emerald-ultra-light;
  margin: 0 10px 20px 10px;
  position: relative;
  &.empty {
    border-style: dashed;
    background-color: @color-emerald-ultra-light;
    border-color: @color-grey-border;
  }
  .photo {
    height: 220px;
  }
  .name {
    .ellipsis();
    padding: 0 20px;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    color: @color-emerald;
    border-top: 1px solid @color-emerald-ultra-light;
    line-height: 50px;
    height: 50px;
    text-align: center;
    background-color: white;
  }
}

/**
 * event card -new
 */
.event-card-inner-new {
  .box-shadow(fade(@color-grey-light, 20%) 0 3px 5px);
  .border-radius(5px);
  margin: 0 10px;
  background-color: white;
  position: relative;
  height: 237px;
  overflow: hidden;
  @media @phablet {
    height: 307px;
  }
}

.event-card-new {
  .user-select(none);
  width: 100%;
  padding-bottom: 20px;
  cursor: pointer;

  @media @phablet {
    float: left;
    width: 50%;
  }

  @media @tablet-wide {
    width: ~"calc(100%/3)";
  }

  &.is-pending {
    cursor: default;
  }

  &.has-one-action {
    .photo .filter .edit {
      position: absolute;
      left: 0px;
      right: 0px;
      margin: 0px auto;
      width: 70%;
    }
  }

  &.empty {
    .photo {
      background-color: #e1efe8;
    }
  }

  &.has-unread {
    .stats .count-of-unread {
      color: #c16ad6;
    }
  }

  .flag-draft {
    display: none;
    position: absolute;
    left: 18px;
    top: 17px;
    letter-spacing: 0.09em;
    padding: 5px 20px;
    text-align: center;
    text-transform: uppercase;
    color: white;
    background-color: rgba(90, 90, 90, 0.6);
    z-index: 1;
    font-weight: 400;
    .border-radius(3px);
    font-size: 10px;
    .transition(opacity 0.2s 0.3s ease-in-out);
  }

  .photo {
    .cover();
    .border-radius(5px 5px 0 0);
    height: 202px;
    background-color: @color-grey-light;
    position: relative;
    @media @mobile {
      height: 162px;
    }

    .empty-text {
      .opacity(0.56);
      font-weight: 100;
      font-size: 55px;
      color: #ffffff;
      text-align: center;
      line-height: 215px;
      display: block;
    }

    .filter {
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      .border-radius(5px 5px 0 0);
      .opacity(0);
      .transition(opacity 0.2s ease-in-out);
      .date {
        position: absolute;
        left: 21px;
        top: 21px;
        color: white;
        font-size: 14px;
        font-weight: 600;
        @media @mobile {
          top: 17px;
          font-size: 11px;
        }
      }

      .view,
      .edit {
        .pill(32px);
        background: rgba(255, 255, 255, 0.15);
        .border-radius(79px);
        text-transform: none;
        font-weight: 600;
        font-size: 20px;
        color: #ffffff;
        line-height: 24px;
        padding: 14px 18px;
        letter-spacing: 0px;

        text-align: center;
        width: ~"calc(50% - 30px)";
        margin: 0 20px 0 10px;
        float: left;
        position: relative;
        top: 50%;
        transform: translateY(-50%);

        &:hover {
          background: rgba(255, 255, 255, 0.25);
        }
      }
      .view {
        margin: 0 10px 0 20px;
      }
      .view-only {
        margin: 0px auto;
        float: none;
        left: 0px;
        right: 0px;
        position: absolute;
      }
    }
  }

  .about {
    .border-radius(0 0 5px 5px);
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    overflow: hidden;
    padding: 20px 20px 30px 20px;
  }

  .name {
    font-weight: 600;
    margin-bottom: 6px;
    font-size: 25px;
    color: #000000;
    .ellipsis();

    @media @mobile {
      white-space: normal;
      overflow: visible;
      text-overflow: initial;
      font-size: 14px;
    }
  }

  .stats {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #858585;

    .sep {
      margin: 0px 6px;
    }

    @media @mobile {
      font-size: 11px;
    }
  }

  &.draft {
    .flag-draft {
      display: block;
    }
  }

  &.is-pending,
  &:hover {
    .filter {
      .opacity(1);
      .transition-delay(0.1s);
    }
    .flag-draft {
      .opacity(0);
      .transition-delay(0s);
    }
  }
}
