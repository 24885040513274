.lennd {
  .ql-editor p,
  .ql-editor ol,
  .ql-editor ul,
  .ql-editor pre,
  .ql-editor blockquote,
  .ql-editor h1,
  .ql-editor h2,
  .ql-editor h3,
  .ql-editor h4,
  .ql-editor h5,
  .ql-editor h6 {
    margin: 1em 0;
  }
  .ql-editor p:first-child,
  .ql-editor ol:first-child,
  .ql-editor ul:first-child,
  .ql-editor pre:first-child,
  .ql-editor blockquote:first-child,
  .ql-editor h1:first-child,
  .ql-editor h2:first-child,
  .ql-editor h3:first-child,
  .ql-editor h4:first-child,
  .ql-editor h5:first-child,
  .ql-editor h6:first-child {
    margin-top: 0;
  }
}
