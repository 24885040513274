.lennd {
  .rw-widget .rw-input {
    font-weight: 600;
    border-radius: 0;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    box-shadow: none;
    padding: 8px;
  }
  .rw-widget {
    border-radius: 2px;
  }
  .rw-combobox:active,
  .rw-datetimepicker:active,
  .rw-dropdownlist:active,
  .rw-header > .rw-btn:active,
  .rw-numberpicker .rw-btn.rw-state-active,
  .rw-combobox:active.rw-state-focus,
  .rw-datetimepicker:active.rw-state-focus,
  .rw-dropdownlist:active.rw-state-focus,
  .rw-header > .rw-btn:active.rw-state-focus,
  .rw-widget.rw-state-focus,
  .rw-widget.rw-state-focus:hover,
  .rw-numberpicker .rw-btn.rw-state-active.rw-state-focus {
    box-shadow: none;
    border: 1px solid #6944d1;
  }
}
