/**
 * gemini-scrollbar
 * @version 1.2.9
 * @link http://noeldelgado.github.io/gemini-scrollbar/
 * @license MIT
 */

/* @helper: used to measure the scrollbar width on a temporal element */
.gm-test {
  width: 100px;
  height: 100px;
  position: absolute;
  top: -9999px;
  overflow: scroll;
  -ms-overflow-style: scrollbar;
}

/* disable selection while dragging */
.gm-scrollbar-disable-selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* fallback for native floating scrollbars */
.gm-prevented {
  -webkit-overflow-scrolling: touch;
  height: 100%;
  overflow: auto;

  .gm-scroll-view {
    .flex(1);
    height: 100%;
    overflow: auto;
  }
}
.gm-prevented .gm-scrollbar {
  display: none;
}

.gm-scrollbar-flex {
  .flex-block();
  .flex-direction(column);
  .flex(1);
  overflow: auto;
  position: relative;
}

/* actual gemini-scrollbar styles */
.gm-scrollbar-container {
  position: relative;
  overflow: hidden !important;
  width: 100%;
  height: 100%;
}

.gm-scrollbar {
  position: absolute;
  right: 2px;
  bottom: 2px;
  z-index: 1;
  border-radius: 3px;
}

.gm-scrollbar.-vertical {
  width: 6px;
  top: 2px;
}

.gm-scrollbar.-horizontal {
  height: 6px;
  left: 2px;
}

.gm-scrollbar .thumb {
  position: relative;
  display: block;
  width: 0;
  height: 0;
  cursor: pointer;
  border-radius: inherit;
  background-color: rgba(0, 0, 0, 0.2);
}

.gm-scrollbar .thumb:hover,
.gm-scrollbar .thumb:active {
  background-color: rgba(0, 0, 0, 0.3);
}

.gm-scrollbar.-vertical .thumb {
  width: 100%;
}

.gm-scrollbar.-horizontal .thumb {
  height: 100%;
}

.gm-scrollbar-container .gm-scroll-view {
  width: 100%;
  height: 100%;

  /*
    Setting this to overflow-y instead of overflow, to fix layout issues
    - however, this may cause further layout issues, for when we need to
    - scroll horizontally, not just vertically
   */
  overflow-y: scroll;

  -webkit-overflow-scrolling: touch;
}

/* @option: autoshow */
.gm-scrollbar-container.gm-autoshow .gm-scrollbar {
  opacity: 0;
  transition: opacity 120ms ease-out;
}
.gm-scrollbar-container.gm-autoshow:hover .gm-scrollbar,
.gm-scrollbar-container.gm-autoshow:focus .gm-scrollbar {
  opacity: 1;
  transition: opacity 340ms ease-out;
}
