.lenndTheme {
  overflow: hidden;
  .box-shadow(2px 3px 5px rgba(0, 0, 0, 0.1));
  position: relative;
  border: 1px solid @color-emerald;
  background-color: @color-emerald-ultra-light;
  color: @color-emerald;
  font-weight: 600;
  text-align: center;
  font-size: 15px;
  .border-radius(3px);
}
