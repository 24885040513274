.embeddedServiceHelpButton .helpButton .uiButton {
  background-color: #425cc3 !important;
  font-family: Mulish, sans-serif !important;
}
.embeddedServiceHelpButton .helpButton .uiButton:focus {
  outline: 1px solid #425cc3 !important;
}
.embeddedServiceSidebarButton.uiButton--inverse .label {
  color: #425ccd;
}
.dockableContainer {
  font-family: Mulish, sans-serif !important;
}
p[embeddedService-chatHeaderAnnouncement_chatHeaderAnnouncement] {
  background-color: rgba(0, 0, 0, 0.9);
}
.embeddedServiceSidebarMinimizedDefaultUI {
  background: #425ccd;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 52%);
  margin: 0 75px 16px 0;
  height: auto;
  padding: 6px;
  min-width: 0;
  width: auto;
}
.embeddedServiceSidebarMinimizedDefaultUI.helpButton:hover {
  background: #3449a4;
}
.embeddedServiceSidebar.layout-docked .dockableContainer {
  margin-bottom: 80px;
}
.sidebarHeader[embeddedService-chatHeader_chatHeader] {
  background: #425ccd;
}
.embeddedServiceSidebarButton {
  background-color: #425ccd;
  text-transform: uppercase;
  font-weight: 700;
  font-family: Mulish, sans-serif !important;
}
.embeddedServiceSidebarButton:not(:disabled):focus,
.embeddedServiceSidebarButton:not(:disabled):hover {
  background: #3449a4;
}
.embeddedServiceSidebarFormField input,
.embeddedServiceSidebarFormField select {
  font-family: Mulish, sans-serif !important;
}
.embeddedServiceSidebarFormField .uiInput .required {
  position: relative;
  color: #d32f2f;
  left: 3px;
}
.embeddedServiceSidebarFormField .uiInput .uiLabel-left {
  line-height: 1.4;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 3px;
}
.embeddedServiceSidebarForm .embeddedServiceSidebarFormField {
  margin-bottom: 15px;
}
.embeddedServiceSidebarFormField .uiInputSelect:after {
  border-top: 7px solid rgba(0, 0, 0, 0.6);
}
.uiInputDefaultError .form-element__help {
  font-size: 12px;
  margin-top: 0;
  color: #d32f2f;
}
.embeddedServiceSidebar.layout-docked .dockableContainer {
  border-radius: 8px;
}

#attend-video-wrapper {
  .App-header {
    background-color: #222;
    height: 40px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }

  .App-header h1 {
    font-size: 16px;
    font-weight: 200;
  }

  .App-logo {
    height: 60%;
    width: auto;
  }

  .App-main {
    position: relative;
    width: 75vw;
    height: calc(75vw * 0.6);
    margin: 10px auto;
    border: 1px solid lightblue;
  }

  .App-control-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  .App-control-container.hidden {
    display: none;
  }

  .App-control-container .ots-video-control {
    width: 50px;
    height: 50px;
    margin: 20px 0 !important;
    border: 2px solid white;
    border-radius: 50%;
    background-position: center;
    background-color: rgba(27, 134, 144, 0.4);
    background-color: lightgrey;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .App-control-container .ots-video-control.audio {
    background-image: url(https://assets.tokbox.com/solutions/images/icon-mic.png);
  }

  .App-control-container .ots-video-control.audio:hover,
  .App-control-container .ots-video-control.audio.muted {
    background-image: url(https://assets.tokbox.com/solutions/images/icon-muted-mic.png);
  }

  .App-control-container .ots-video-control.video {
    background-image: url(https://assets.tokbox.com/solutions/images/icon-video.png);
  }

  .App-control-container .ots-video-control.video.muted {
    background-image: url(https://assets.tokbox.com/solutions/images/icon-no-video.png);
  }

  .App-control-container .ots-video-control.end-call {
    background-image: url(https://assets.tokbox.com/solutions/images/icon-hang-up.png);
    background-color: red;
  }

  .App-video-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .App-mask {
    width: 100%;
    height: 100%;
    position: relative;
    color: white;
    background: rgba(27, 134, 144, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .App-mask .react-spinner {
    position: absolute;
  }

  .App-mask .message {
    font-weight: 200;
  }

  .App-mask .message.with-spinner {
    position: absolute;
    top: 55%;
  }

  .App-mask .message.button {
    border: 1px solid white;
    padding: 20px 40px;
    border-radius: 6px;
    background-color: Transparent;
    color: white;
    font-family: sans-serif;
    font-size: medium;
  }

  .App-mask .message.button:focus:active,
  .message.button:focus,
  .message.button:active {
    background-image: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .App-video-container .video-container {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .App-video-container .video-container.small {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 160px;
    height: 96px;
    border: 1px solid #fcba00;
    z-index: 2;
  }

  .App-video-container .video-container.small.left {
    left: 20px;
    border: 1px solid #00fcc2;
  }

  .App-video-container .video-container.hidden {
    display: none;
  }

  .App-video-container .video-container.active-gt2 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
  }

  .App-video-container .video-container.active-gt2.hidden {
    display: none;
  }

  .App-video-container .video-container.active-gt2 .OT_subscriber {
    margin: 0;
    padding: 0;
    display: inline-block;
    font-size: 0;
    flex-basis: 50%;
    box-sizing: border-box;
    width: auto !important;
    height: auto !important;
  }

  .App-video-container
    .video-container.active-gt2.active-odd
    .OT_subscriber:first-child {
    flex-basis: 100%;
  }
}

// for billing page

.servicebot-base .servicebot-btn-primary {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background-color: #7537fc !important;
  border-radius: 5 !important;
  border: none !important;
  color: #fff !important;
  cursor: pointer !important;
  font-family: inherit !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  height: 48px !important;
  justify-content: center !important;
  line-height: 48px !important;
  outline: none !important;
  padding: 0 25px !important;
  text-decoration: none !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  white-space: nowrap !important;
}

.servicebot-base .servicebot-btn-primary:hover,
.servicebot-base .servicebot-btn-primary:active,
.servicebot-base .servicebot-btn-primary:focus {
  background-color: #425bc2 !important;
  color: #fff !important;
}

.servicebot-base .powerby,
.servicebot-base .__plan-item.__plan-2,
.servicebot-base.__pricing > .servicebot-row,
.servicebot-base .servicebot-divider {
  display: none !important;
}

.servicebot-base .__footer,
.servicebot-base .__header {
  padding: 0 !important;
}

.servicebot-base .servicebot-pricing-table,
.servicebot-base button,
.servicebot-base .__tier.__tier-1,
.servicebot-base h3 {
  margin: 0 !important;
}

.servicebot-base h3 {
  font-size: 24px !important;
  text-transform: capitalize !important;
  color: #111 !important;
}

.servicebot-base .servicebot-pricing-table .__tier {
  box-shadow: none !important;
}

.servicebot-base .__amount {
  font-size: 32px !important;
}

.servicebot-base .__price-suffix {
  font-size: 15px;
  color: #111;
}

.servicebot-base .__label,
.servicebot-base .__amount {
  font-weight: 600;
}

.servicebot-base .__tier-plan {
  padding-bottom: 16px !important;
}

.ui-kit-fieldtypes-datetime-input {
  color: rgb(73, 73, 73);
  height: 32px;
  border: 1px solid #e7e6ea;
  padding: 0 8px;
  font-weight: 600;
  border-radius: 4px;
  width: 100%;
  font-size: 14px;
}
