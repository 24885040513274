// Catering Checkbox for heading
.catering-meal-list-heading {
  .rc-checkbox {
    display: flex;
    justify-content: center;
  }
  .rc-checkbox-checked .rc-checkbox-inner:after {
    top: 0;
    left: 3px;
  }
  .rc-checkbox-inner:after {
    top: 0;
    left: 3px;
  }
  .rc-checkbox-inner {
    height: 12px;
    width: 12px;
    margin-right: 2px;
    border-radius: 2px;
    border-color: inherit;
  }
}

.catering-checkbox-grid {
  .rc-checkbox-checked .rc-checkbox-inner:after,
  .rc-checkbox-inner:after {
    left: 5px;
    top: 0;
    display: table;
    width: 5px;
    height: 7px;
  }
  .rc-checkbox-inner {
    border-color: #dddddd;
    border-radius: 2px;
  }
  .rc-checkbox-checked .rc-checkbox-inner {
    border-color: #9013fe;
    background-color: #9013fe;
  }
}

// form approval modal
.form-send-approval-modal {
  .rc-checkbox-checked .rc-checkbox-inner:after {
    top: 0;
    left: 3px;
  }
  .rc-checkbox-inner:after {
    top: 0;
    left: 3px;
  }
  .rc-checkbox-inner {
    height: 12px;
    width: 12px;
    margin-right: 2px;
    border-radius: 2px;
    border-color: inherit;
  }
}
