.ReactTable {
  .rt-thead .rt-td.-sort-asc,
  .rt-thead .rt-th.-sort-asc {
    box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.12);
  }

  .rt-thead .rt-td.-sort-desc,
  .rt-thead .rt-th.-sort-desc {
    box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, 0.12);
  }
}

.ReactTable--Lennd {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  border: none !important;
  font-size: 14px;
  z-index: 1;

  &.ReactTable--Lennd-Fixed-Headers {
    .rt-thead {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }

  &.ReactTable--Lennd-Has-Column-Actions {
    .rt-th:hover {
      background-color: #f2f1f4;
    }
  }

  &.ReactTable--Lennd-Simple {
    position: relative;
    left: inherit;
    top: inherit;
    width: inherit;
    height: inherit;

    .rt-table .rt-tbody .rt-tr:hover {
      background-color: #f8f8f8;
    }

    .rt-th,
    .rt-td {
      padding: 3px 5px;
    }
  }

  &.ReactTable--Lennd-Expandable {
    .rt-table .rt-tbody .rt-tr:hover {
      cursor: pointer;
      background-color: #fafafa !important;
    }

    .ReactTable--Lennd-Embedded .rt-table .rt-tbody .rt-tr:hover {
      cursor: inherit;
      background-color: inherit !important;
    }

    .ReactTable--Lennd-Embedded .rt-thead .rt-tr:hover {
      cursor: inherit;
      background-color: inherit !important;
    }
  }

  .rt-noData {
    pointer-events: inherit;
  }

  .rt-resizable-header-content {
    width: 100%;
  }

  .rt-table {
    height: 100%;
    display: block;

    .rt-thead {
      background-color: #f8f8f8;
      box-shadow: none;
    }

    .rt-thead.-headerGroups {
      padding: 5px 0;
    }

    .rt-thead .rt-td.-sort-asc,
    .rt-thead .rt-th.-sort-asc {
      box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.12);
    }

    .rt-thead .rt-td.-sort-desc,
    .rt-thead .rt-th.-sort-desc {
      box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, 0.12);
    }

    .rt-tr {
      text-align: left;
      height: 100%;
    }

    .rt-th {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 15px;
      color: #494949;
      border-right: none;
      text-align: left;
    }

    .rt-th.sortable {
      user-select: none;
      cursor: pointer;
    }

    .rt-th.is-sorting {
      background: #efedf1;
    }

    .rt-tbody {
      // @NOTE: Overflow scroll is required here to allow for sticky column headers
      overflow: auto;
      display: block;

      .rt-td {
        display: flex;
        align-items: center;
        border-right: none;
      }

      .rt-tr-group {
        background: white;
        border-bottom: 1px solid #ececec;
      }
    }
  }
}

.ReactTable.ReactTable-record {
  border: none;

  .-pagination {
    box-shadow: none;

    .-center {
      flex: 2;
    }
  }

  .rt-tbody {
    font-size: 13px;

    .rt-td {
      border: none;
    }

    .rt-tr {
      align-items: center;

      &:hover {
        background-color: #fcfbfc;
      }
    }
  }

  .rt-thead {
    &.-header {
      box-shadow: none;
    }

    .rt-tr {
      text-align: left;
      font-size: 14px;
      color: #494949;
      font-weight: 600;
    }

    .rt-td,
    .rt-th {
      border: none;
    }
  }
}
