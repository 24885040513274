//---------------------------------------------------
//  Custom Mixins
//---------------------------------------------------
.user-select(@string) {
  -webkit-user-select: @string;
  -moz-user-select: @string;
  -khtml-user-select: @string;
  -ms-user-select: @string;
  user-select: @string;
}

.center() {
  position: absolute;
  left: 50%;
  top: 50%;
  .transform(translate(-50%, -50%));
}

.center-vertical() {
  position: absolute;
  top: 50%;
  .transform(translateY(-50%));
}

.center-horizontal() {
  position: absolute;
  left: 50%;
  .transform(translateX(-50%));
}

.font-smoothing() {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.word-wrap() {
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.backface-visibility(@visibility) {
  -webkit-backface-visibility: @visibility;
  -moz-backface-visibility: @visibility;
  -ms-backface-visibility: @visibility;
  backface-visibility: @visibility;
}

.cover() {
  .background-size(cover);
  background-position: center;
}

.default-transition() {
  .transition(all 0.2s ease-in-out);
}
