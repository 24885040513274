/**
* A stylesheet to manage all the buttons
*/

.pill(@height: 32px) {
  .default-transition();
  .border-radius(@height / 2);
  .user-select(none);
  color: white;
  background-color: @color-emerald;
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  border: none;
  display: inline-block;
  padding: 15px ((@height / 2) + 2);
  text-transform: uppercase;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: darken(@color-emerald, @darkenPercentage);
  }

  // Icons within buttons
  i,
  i::before,
  .title {
    display: inline-block;
    line-height: inherit !important;
    // I know, I know... an important rule! This overrides font-awesome.
    // get rid of font awesome, and we can get rid of this
  }

  i {
    margin-right: 10px;
  }

  &.icon-right {
    i {
      margin-right: 0;
      margin-left: 10px;
    }
  }

  // Button Sizes
  &.small {
    .border-radius((@height / 2) + 2);
    font-size: 13px;
    line-height: 16px;
    padding: 10px ((@height / 2) + 2);
  }
  &.lowercase {
    text-transform: capitalize;
    letter-spacing: normal;
  }

  // Button Colors
  &.white {
    background-color: @color-grey-border;
    color: @color-grey-type;
    &:hover {
      background-color: white;
      color: @color-grey-type-dark;
    }
  }
  &.pure-white {
    background-color: white;
    color: @color-grey-type;
    &:hover {
      background-color: white;
      color: @color-grey-type-dark;
    }
  }
  &.orange {
    background-color: @color-orange;
    &:hover {
      background-color: darken(@color-orange, @darkenPercentage);
    }
  }
  &.grey {
    background-color: @color-grey-border;
    color: @color-grey-mid;
    &:hover {
      background-color: darken(@color-grey-border, @darkenPercentage);
      color: @color-grey-mid;
    }
  }
  &.clear {
    background-color: transparent;
    color: @color-grey-medium;
    &:hover {
      background-color: transparent;
      color: @color-grey-type-dark;
    }
  }
  &.light {
    .box-shadow(inset 0 0 0 1px @color-emerald);
    background-color: @color-emerald-ultra-light;
    color: @color-almost-black;
    &:hover {
      background-color: @color-emerald-ultra-light;
      color: @color-almost-black;
    }
  }
  &.outline {
    .box-shadow(inset 0 0 0 1px currentColor);
    .opacity(0.8);
    background-color: transparent;
    font-weight: 600;
    &:hover {
      .opacity(1);
      background-color: transparent;
      color: currentColor;
    }
  }
  &.outline-emerald {
    .box-shadow(inset 0 0 0 1px currentColor);
    .opacity(0.8);
    color: @color-emerald;
    background-color: transparent;
    font-weight: 600;
    &:hover {
      .opacity(1);
      background-color: transparent;
      color: @color-emerald;
    }
  }
}

.button(@radius: 4px, @height: 30px, @size: 11px, @case: uppercase) {
  .default-transition();
  .border-radius(@radius);
  .user-select(none);
  color: white;
  background-color: @color-emerald;
  display: inline-block;
  font-size: @size;
  line-height: @height;
  height: @height;
  font-weight: 600;
  position: relative;
  padding: 0 15px;
  cursor: pointer;
  border: none;
  text-transform: @case;
  span,
  i {
    line-height: inherit !important;
    // I know, I know... an important rule! This overrides font-awesome.
    // get rid of font awesome, and we can get rid of this
  }
  &:hover {
    background-color: darken(@color-emerald, @darkenPercentage);
  }
  @media @phablet {
    font-size: (@size + 1);
  }
  @media @desktop {
    font-size: (@size + 2);
  }
  &.red {
    background: @color-red-light;
    &:hover {
      background-color: darken(@color-red-light, @darkenPercentage);
    }
  }
  &.orange {
    background-color: @color-orange;
    &:hover {
      background-color: darken(@color-orange, @darkenPercentage);
    }
  }
  &.white {
    .box-shadow(~"inset 0 0 0 2px rgba(0,0,0,0.13)");
    background-color: @color-grey-ultra-light;
    color: @color-grey-type;
    &:hover {
      background-color: white;
      color: @color-grey-type-dark;
    }
  }
  &.tiny {
    border: 1px solid @color-grey-light;
    background-color: @color-grey-super-light;
    color: @color-grey-type;
    &:hover {
      background-color: white;
      color: @color-grey-type-dark;
    }
  }
  &.grey {
    background-color: @color-grey-medium;
    color: white;
    &:hover {
      background-color: @color-grey-medium;
      color: white;
    }
  }
  &.clear {
    background-color: transparent;
    color: white;
    &:hover {
      background-color: @color-grey-ultra-light;
      color: @color-grey-type-dark;
    }
  }
}
