/**
 * Responsive Breakpoints
 * Source: http://blog.scur.pl/2012/06/variable-media-queries-less-css/
 */

.font-tiny {
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.font-small {
  font-size: 14px;
  font-weight: 600;
}

.font-medium {
  font-size: 18px;
  font-weight: 600;
}

.font-large {
  font-size: 32px;
  font-weight: 600;
}

.font-xlarge {
  font-size: 40px;
  font-weight: 600;
}
