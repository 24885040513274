.drop-element {
  position: absolute;
  display: none;
  z-index: 100;
}

.drop-element.drop-open {
  display: block;
}

.drop-element.drop-theme-basic {
  max-width: 100%;
  max-height: 100%;
}
