/**
 * Overlays
 */
.overlay-container {
  .default-transition();
  .opacity(0);
  pointer-events: none;
  background: rgba(0, 0, 0, 0.66);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
}
.overlay-inner {
}

.overlay-content {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: white;
  .border-radius(3px);
}

/**
 * Overlays - Photo
 */
.overlay-photo {
  .center-vertical();
  width: 678px;
  height: 562px;
  bottom: 0;
  text-align: center;

  .close {
    position: absolute;
    right: 3px;
    top: -30px;
    font-weight: 600;
    font-size: 14px;
    color: white;
    line-height: 28px;

    i {
      font-size: 14px;
      color: white;
      line-height: 14px;
      margin-right: 1px;
    }
  }

  .photo-wrapper {
    height: 450px;
    width: 100%;
    background-color: black;
    text-align: center;
    position: relative;
    overflow: hidden;
    .border-radius(3px 3px 0 0);

    &:hover {
      .favorite {
        display: block;
      }
    }

    .favorite {
      display: none;
      width: 119px;
      height: 40px;
      text-align: center;
      background: rgba(30, 30, 30, 0.41);
      font-weight: 600;
      font-size: 15px;
      color: white;
      line-height: 39px;
      .box-shadow(0 0 5px 0 rgba(0,0,0,0.09));
      .border-radius(28px);
      position: absolute;
      left: 20px;
      top: 20px;
      z-index: 11;

      &:hover {
        background: rgba(30, 30, 30, 0.8);
      }

      &.disabled {
        display: none !important;
      }

      &.on {
        background-color: rgba(255, 255, 255, 0.92);
        color: black;

        .txt-favorite {
          display: none;
        }

        .txt-favorited {
          display: inline-block;
        }

        &:hover {
          background-color: rgba(255, 255, 255, 0.92);

          .txt-favorited {
            display: none;
          }
          .txt-unfavorite {
            display: inline-block;
          }
        }

        i {
          color: red;
        }
      }

      .txt-favorite {
        display: inline-block;
      }

      .txt-favorited {
        display: none;
      }

      .txt-unfavorite {
        display: none;
      }

      i {
        color: rgba(255, 255, 255, 0.76);
        margin-right: 5px;
      }
    }

    .arrow {
      .text-shadow-glow-dark();
      .user-select(none);
      z-index: 10;
      font-size: 65px;
      color: white;
      position: absolute;
      height: 100%;
      width: 60px;
      text-align: center;
      cursor: pointer;
      line-height: 400px;

      &:hover {
        color: @color-grey-mid-light;
      }
    }

    .arrow-left {
      left: 0;
    }

    .arrow-right {
      right: 0;
    }

    img {
      .center-vertical();
      max-width: 678px;
      max-height: 450px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }

  .meta-bar {
    height: 55px;
    border-bottom: 1px solid @color-grey-border;

    .event-name {
      float: left;
      font-weight: 600;
      font-size: 28px;
      color: @color-grey;
      line-height: 34px;
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 12px;
      text-align: left;
      max-width: 638px;
      .ellipsis();
      .opacity(0.81);
    }

    .social-buttons {
      float: right;
    }
  }

  .vendors-bar {
    background: @color-grey-super-light;
    height: 57px;
    text-align: left;
    padding-top: 10px;
    .border-radius(0 0 3px 3px);

    .label {
      .opacity(0.81);
      font-weight: bold;
      font-size: 18px;
      color: @color-grey;
      line-height: 24px;
      margin-left: 20px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }

    ul {
      display: inline-block;
      vertical-align: middle;

      li {
        .border-radius(100px);
        .cover();
        float: left;
        width: 33px;
        height: 33px;
        font-size: 14px;
        background-color: @color-grey-border;
        margin-right: 4px;
        cursor: pointer;
        position: relative;
        text-align: center;
        font-weight: 100;
        color: white;
        line-height: 34px;

        &.empty {
          background-color: @color-emerald-ultra-light;
        }

        &.remaining {
          background-color: @color-red-dark;
        }
      }
    }
  }
}
