.lennd-wysiwyg-content {
  a {
    text-decoration: underline;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }
}

.portal-message-text {
  a {
    color: #425bc2;
  }
}
