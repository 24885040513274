@tasksSidebarWidth: 215px;
@tasksSidebarSidePadding: 16px;
@detailsSidebarWidth: 320px;

/**
 * tasks - wrapper
 */
.platform-body-sub-body-tasks {
  background-color: #fafafa;
}

.tasks-wrapper {
  background-color: #fff;
}

.details-sidebar-visible {
  .tasks-detail-sidebar {
    width: 340px;
  }

  .tasks-tasks-header .title {
    float: none;
    margin-bottom: 10px;
  }
}

/**
 * tasks - tasks
 */
.tasks-tasks {
  padding-bottom: 50px;
}
.tasks-tasks-header {
  padding: 22px 36px 29px 36px;
  background: #ffffff;
  margin-bottom: 16px;
  .box-shadow(1px 1px 4px 0px rgba(0,0,0,0.14));

  .title,
  .btn-add-people,
  .btn-sort {
    float: left;
  }
  .btn-add-people,
  .btn-sort {
    font-weight: bold;
    font-size: 14px;
    color: #666666;
    line-height: 14px;
    border: 1px solid #d8d8d8;
    height: 32px;
    cursor: pointer;
    text-align: center;
    padding: 0px 20px;
    line-height: 29px;
    .border-radius(19px);

    i {
      font-size: 12px;
      color: #bbbbbe;
      line-height: 14px;
      margin-right: 5px;
    }

    &:hover {
      background-color: #eee;
    }
  }
  .btn-add-people {
    margin-right: 8px;
  }
  .btn-sort {
  }
  .status {
    .flex(1);
    text-align: right;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
  }
  .status-label {
    color: #a7a7a7;
    margin-right: 3px;
  }
  .status-percent {
    color: #00cc83;
  }
}
.tasks-tasks-header-name {
  line-height: 31px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tasks-tasks-header-title {
  .flex-block();
  .flex(1);
  font-weight: 600;
  font-size: 26px;
  color: #484848;
  margin-right: 11px;
}
.tasks-tasks-add {
  margin-top: 10px;

  .input-wrapper {
    margin: 0px;
    input[type="text"] {
      .border-radius(3px);
      background-color: #f7f7f7;
    }
  }
}

.tasks-tasks-list {
  padding: 0px 36px;
  margin-bottom: 40px;
}

.tasks-tasks-list-title {
  font-size: 13px;
  color: #333;
  letter-spacing: 1px;
  line-height: 16px;
  margin: 0 36px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 13px;
  border-bottom: 1px dotted #ddd;
  padding-bottom: 5px;
  padding-left: 10px;
}

.tasks-tasks-list-row {
  padding: 8px 11px;
  background-color: #fff;
  margin-bottom: 5px;
  cursor: pointer;
  min-height: 44px;
  font-size: 16px;
  font-weight: 600;
  .border-radius(4px);
  .box-shadow(0px 0px 3px 0px rgba(0, 0, 0, 0.11));

  .name-name {
    vertical-align: middle;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .priority {
    font-size: 20px;
    height: 29px;
    line-height: 41px;
    margin-left: 13px;
    float: right;
  }

  .icon-star {
    color: #eee;

    &:hover {
      color: darken(#eee, 10%);
    }
  }

  &.is-priority {
    border-left: 5px solid #f2e110;

    .icon-star {
      color: #f2e110;
    }
  }

  .checkbox {
    &:hover {
      background: #f1f1f1;
    }
  }

  &.has-unread {
    .name-unread {
      display: inline-block;
      vertical-align: middle;
      margin-right: 7px;
      float: left;
      margin-top: 3px;
    }
  }

  &.has-list-name {
    .name {
      line-height: 17px;
      margin-top: 0px;
    }
  }

  &.completed {
    background-color: rgba(255, 255, 255, 0);

    .name {
      color: #b7b7b7;
    }
    .checkbox {
      color: #fff;
      ///background: #00CC83;
      //border: 1px solid #00CC83;
      background: #cccccc;
      border: 1px solid #d0d0d0;

      &:hover {
        background: #cccccc;
      }
    }

    .checkbox .check {
      display: inline-block;
    }
    .due {
      font-weight: bold;
      color: #a7a7a7;
      text-transform: uppercase;
    }
  }

  &.selected,
  &.selected:hover {
    background: #d1f1e1;
  }

  &:hover {
    // .box-shadow(0px 0px 5px 0px rgba(0, 0, 0, 0.28));
    // Using outline instead of box-shadow for an increase in scroll performance
    outline: 1px solid rgba(0, 0, 0, 0.09);
  }

  .checkbox {
    border: 1px solid #afafaf;
    text-align: center;
    width: 18px;
    max-width: 20%;
    height: 18px;
    font-size: 11px;
    margin-right: 12px;
    line-height: 17px;
    .border-radius(4px);

    .check {
      display: none;
    }
  }

  .name-unread {
    display: none;
    font-weight: 600;
    font-size: 11px;
    color: #ffffff;
    letter-spacing: 0.2px;
    line-height: 14px;
    background: #00cd81;
    padding: 0px 6px;
    margin-right: 2px;
    height: 14px;
    vertical-align: top;
    .border-radius(5px);
  }

  .list-name {
    font-weight: 600;
    font-size: 11px;
    color: #a7a7a7;
    line-height: 13px;
    display: block;
  }

  .members {
    .member {
      float: left;
      background: #fff;
      width: 28px;
      height: 28px;
      font-weight: 600;
      font-size: 10px;
      color: #666666;
      letter-spacing: 0.6px;
      border: 1px solid #d8d8d8;
      text-align: center;
      line-height: 26px;
      margin-left: 4px;
      .background-size(cover);
      .border-radius(4px);
    }
  }

  .due {
    font-weight: 600;
    font-size: 11px;
    color: #1ea575;
    line-height: 27px;
    margin-left: 15px;
    text-align: right;
  }
}

/**
 * tasks - lists
 */
.tasks-lists-sidebar-side-padding {
  padding-left: 26px;
  padding-right: 20px;
  margin-top: 6px;
}

.tasks-lists-sidebar {
  background: #4f4086;
  width: 100%;
  height: 100%;
  padding-top: 22px;
  padding-bottom: 18px;
}

.tasks-lists-sidebar-title {
  font-size: 22px;
  color: #fff;
  line-height: 26px;
  margin-bottom: 10px;
}

.tasks-lists-sidebar-list {
  padding-left: @tasksSidebarSidePadding;
  margin-bottom: 20px;
}

.tasks-lists-sidebar-list-row {
  font-weight: 600;
  font-size: 16px;
  color: #d1c9ef;
  margin: 3px 0px 3px 0px;
  line-height: 16px;
  padding: 5px 11px;
  cursor: pointer;
  .border-radius(3px 0px 0px 3px);

  .name {
    float: left;
    max-width: 130px;
    word-wrap: break-word;
  }

  .percent {
    font-size: 14px;
    line-height: 15px;
    float: right;
    color: rgb(142, 130, 185);
  }

  &.selected,
  &.selected:hover {
    background: #312f34;

    .percent {
    }
  }

  &:hover {
    background-color: rgba(49, 47, 52, 0.4);

    .percent {
    }
  }
}

.tasks-lists-sidebar-list-sep {
  border-bottom: 1px solid #3a326d;
  margin: 8px 0px;
}

.tasks-lists-sidebar-add-list {
  padding: 0px @tasksSidebarSidePadding;

  .input-wrapper {
    input[type="text"] {
      background-color: #fff;
      height: 37px;
      font-size: 14px;
      line-height: 17px;
      .border-radius(3px);
    }
  }
}

/**
 * task messages
 */
.task-message-compose-view {
  .message-compose-body,
  .messages-body-wrapper {
    position: inherit;
    top: inherit;
    bottom: inherit;
    overflow: inherit;
  }

  .messages-body-wrapper {
    padding: 0px 24px;
  }

  .message-compose-input-wrapper {
    background-color: #fff;
    position: relative;
    margin-bottom: 0;
  }

  .message-log-item {
    margin-top: 0px;
    font-size: 15px;
    color: #a7a7a7;
    line-height: 18px;
  }

  .message-item {
    padding-right: 20px;

    .meta-name {
      max-width: 117px;
    }

    .meta-date {
      margin-left: 0px;
    }

    .message {
      display: block;
      width: auto;
    }

    &.has-user-photo {
      .message {
        margin-left: 48px;
      }
    }
  }

  .message-item .photo {
    width: 36px;
    height: 36px;
    left: 0px;
  }

  .message-item .photo.empty {
    font-size: 15px;
    line-height: 34px;
  }

  .message-log-item .action-image {
    max-width: 180px;
  }

  .message-log-item .action-download .filename {
    max-width: 188px;
  }

  .message-log-item .action-download {
    line-height: 18px;
    font-size: 14px;
  }

  .date-separator {
    margin: 15px 0px;
  }

  .date-separator .date-label {
  }
}

/**
 * tasks - task detail
 */
.tasks-detail-sidebar {
  .box-shadow(-2px 0px 9px 0px rgba(0,0,0,0.19));
  position: relative;
  background: #ffffff;
  width: 0;
  z-index: 2;
  overflow: auto;
}

.tasks-detail-sidebar-save,
.tasks-detail-sidebar-cancel {
  height: 32px;
  .default-transition();
  .user-select(none);
  .border-radius(5px);
  color: white;
  line-height: 23px;
  font-weight: 700;
  letter-spacing: 1px;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #694ace;
  font-size: 13px;
  padding: 5px;
  text-align: center;
  .flex(1);

  &:hover {
    background-color: darken(#694ace, 10%);
  }
}

.tasks-detail-sidebar-cancel {
  margin-left: 10px;
  background: none;
  color: #868686;

  &:hover {
    background: none;
    color: darken(#868686, 10%);
  }
}

.tasks-detail-sidebar-name-edit {
  .box-shadow(none);
  resize: none;
  padding: 0;
  border: 0;
  line-height: 19px;
  font-size: 16px;
  letter-spacing: normal;
  background-color: transparent;
}

.tasks-detail-sidebar-checkbox {
  .flex-block();
  padding: 35px 35px 35px 20px;
  background-color: #d1f1e1;
  border-bottom: 1px solid rgba(204, 204, 204, 0.35);

  .checkbox {
    .flex-block();
    .border-radius(4px);
    .align-items(center);
    .justify-content(center);
    border: 1px solid #afafaf;
    width: 22px;
    height: 22px;
    margin-right: 10px;
    cursor: pointer;
    background-color: white;

    .check {
      font-size: 11px;
      display: none;
    }
  }

  .name {
    .flex(1);
    line-height: 0;
    span {
      font-weight: 600;
      font-size: 16px;
      color: #313232;
      line-height: 19px;
    }
  }

  &.completed {
    .checkbox {
      color: #fff;
      background: #00cc83;
      border: 1px solid #00cc83;
    }
    .checkbox .check {
      display: inline-block;
    }
  }
}

.tasks-detail-sidebar-assigned-to {
  padding: 15px;
  position: relative;
  border-bottom: 1px solid #e8e8e8;

  .members {
    .member {
      float: left;
      background: #fff;
      width: 36px;
      height: 36px;
      font-weight: 600;
      font-size: 13px;
      color: #666666;
      letter-spacing: 0.6px;
      border: 1px solid #d8d8d8;
      text-align: center;
      line-height: 35px;
      margin-right: 4px;
      .background-size(cover);
      .border-radius(4px);
    }
  }
  .title {
    font-weight: 600;
    font-size: 16px;
    color: #666666;
    line-height: 19px;
    margin-bottom: 5px;
  }
  .add-new {
    float: left;
    position: relative;
    background: #f7f7f7;
    border: 1px solid #d9d9d9;
    width: 100%;
    height: 36px;
    text-align: left;
    line-height: 32px;
    .border-radius(3px);
    cursor: pointer;
    .user-select(none);
    padding: 0 8px;
    &:hover {
      background-color: darken(#f7f7f7, 5%);
    }

    .icon-add-user {
      border: 1px solid #666;
      color: #666;
      width: 17px;
      height: 17px;
      line-height: 19px;
      text-align: center;
      margin-top: 9px;
      font-size: 14px;
      .border-radius(100px);
    }

    .icon-down-arrow {
      float: right;
      line-height: 35px;
      margin-right: 5px;
    }

    .label {
      font-weight: 600;
      margin-left: 10px;
    }

    &.short {
      width: 36px;
      text-align: center;

      .icon-add-user {
        margin-top: 8px;
      }

      .icon-down-arrow {
        display: none;
      }

      .label {
        display: none;
      }
    }
  }
}

.tasks-detail-sidebar-assigned-to-dropdown {
  border-bottom: 1px solid #e8e8e8;

  .input-filter {
    position: relative;
  }

  .icon-x {
    position: absolute;
    right: 14px;
    top: 14px;
    color: #aaaaaa;
    cursor: pointer;

    &:hover {
      color: darken(#aaaaaa, 10%);
    }
  }

  .input {
    background-color: #f7f7f7;
    border: 0;
    .border-radius(0);
    .box-shadow(none);
  }

  .option {
    padding: 10px 15px;
    cursor: pointer;
    .flex-block();
    .align-items(center);

    &.add {
      .justify-content(center);
      background-color: #fafafa;
      padding: 15px;
    }

    &:hover {
      background-color: #f7f7f7;
      .check {
        display: inline-block;
      }
    }
    &.is-pending {
      .name {
        line-height: normal;
      }
    }
    &.is-user {
      .photo {
        .border-radius(100px);
      }
    }
    &.no-photo {
      .photo {
        border: 1px solid #d8d8d8;
      }
    }
    &.selected {
      .checkbox {
        color: #fff;
        background: #00cc83;
        border: 1px solid #00cc83;
      }
      .check {
        display: inline-block;
        color: #fff;
      }
    }
  }
  .photo {
    width: 36px;
    height: 36px;
    background: #fff;
    font-weight: 600;
    font-size: 12px;
    color: #666666;
    letter-spacing: 0.6px;
    text-align: center;
    line-height: 35px;
    background-position: center;
    .background-size(cover);
    .border-radius(4px);
  }
  .name {
    .flex(1);
    margin-left: 10px;
    .ellipsis();
  }
  .status {
    display: block;
    font-size: 11px;
    color: #aaa;
    display: block;
    line-height: 13px;
  }
  .checkbox {
    text-align: center;
    width: 22px;
    height: 22px;
    font-size: 11px;
    line-height: 22px;
    .border-radius(4px);
  }
  .check {
    display: inline-block;
    color: #dddddd;
    display: none;
  }
}

.tasks-detail-sidebar-note {
  padding: 15px;
  border-bottom: 1px solid #e8e8e8;

  .save,
  .cancel {
    .pill();
    font-size: 11px;
    padding: 5px 14px;
  }

  .cancel {
    margin-right: 5px;
    background-color: @color-grey-light;
    &:hover {
      background-color: darken(@color-grey-light, @darkenPercentage);
    }
  }

  .textarea {
    height: 104px;
    background-color: #fbfbfb;
    border: 1px solid #d9d9d9;
    .border-radius(5px);
  }

  .note {
    cursor: pointer;
    white-space: pre-wrap;
    display: block;
    max-width: 100%;
    overflow: hidden;

    .title {
      font-weight: 600;
      margin-right: 5px;
      display: block;
    }
  }
}

.tasks-detail-sidebar-comments {
  border-bottom: 1px solid #e8e8e8;
}

.tasks-detail-sidebar-comments-add-message {
  border-top: 1px solid #dbdbdb;
}

.tasks-detail-sidebar-meta {
  font-weight: 600;
  font-size: 12px;
  color: #b7b7b7;
  line-height: 14px;
  position: relative;
  width: 100%;
  height: 50px;

  .details {
    position: absolute;
    left: 0px;
    right: 0px;
    width: 100%;
    z-index: 1;
    text-align: center;
  }

  .collapse,
  .trash {
    z-index: 2;
    top: 10px;
    position: absolute;
    cursor: pointer;
    color: #b7b7b7;
  }

  .collapse {
    left: 10px;
    border: 1px solid #dddddd;
    padding: 0px 2px;
    border-radius: 3px;
    font-size: 10px;
  }

  .trash {
    right: 10px;
  }
}
