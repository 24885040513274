/**
* A stylesheet to manage all the nav elements
*/

.nav-link {
  font-weight: 600;
  font-size: 16px;
  color: #94a3df;
  margin-right: 30px;
  position: relative;
  line-height: 44px;
  white-space: nowrap;
  transition: all 0.2s ease-in-out;
  @media screen and (max-width: 1000px) {
    font-size: 14px;
    margin-right: 20px;
  }

  &:hover {
    color: white;
  }

  &.active {
    color: white;
    &::before {
      display: block;
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: @color-emerald;
    }
  }

  .number {
    color: @color-grey-type;
  }
  .title,
  .count {
    display: inline-block;
  }
  .count {
    margin-left: 10px;
    color: #b4aeda;
    font-weight: 400;
  }

  .options-dropdown-label {
    vertical-align: middle;
  }

  .options-dropdown-inner {
    top: 45px;
  }
}

.nav-link-wrapper {
  &.has-unread .nav-link-count {
    display: inline-block;
  }

  &.active {
    color: white;

    &::before {
      display: none;
    }

    .nav-link-label::before {
      display: block;
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: @color-emerald;
    }
  }
}

.nav-link-label,
.nav-link-count {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.nav-link-count {
  display: none;
  font-weight: 600;
  font-size: 12px;
  color: #534289;
  letter-spacing: 0.2px;
  line-height: 16px;
  background: #b1a6e3;
  padding: 0px 6px;
  margin-left: 6px;
  height: 16px;
  .border-radius(5px);
}

.nav-divider {
  display: block;
  float: left;
  margin: 13px 20px 0px 0px;
  width: 1px;
  background-color: @color-grey-mid;
  height: 19px;
}

.nav-button {
  .pill();
  float: right;
  margin-left: 10px;
}
